interface SignUpFormTranslation {
  pageNotFound: string;
}

const en: SignUpFormTranslation = {
  pageNotFound: 'Ooops! Page not found'
}

const ja: SignUpFormTranslation = {
  pageNotFound: 'ページが見つかりません'
}

export const Translations = {
  1: en,
  2: ja
}
