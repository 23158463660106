import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {
  Translations
} from '../../../../../../translations/shared-module/authenticated-wrapper/side-nav-list/side-nav-list.translation';
import {select, Store} from '@ngrx/store';
import * as SharedSelectors from '../../../../../core/shared.selectors';
import * as fromRoot from '../../../../../../../state/app.state';
import {CommonService} from '../../../../../services/common/common.service';
import {roles} from '../../../../../constants/base.constants.js';

@Component({
  selector: 'app-side-nav-list',
  templateUrl: './side-nav-list.component.html',
  styleUrls: ['./side-nav-list.component.css']
})
export class SideNavListComponent implements OnInit, OnDestroy {

  translations = {}
  languageSubscription: Subscription;
  language: number;
  options: any = [];
  role: any;

  badges = {
    dashboard: 0,
    orders: 0,
    returns: 0
  }

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService) {
    this.translations = Translations;
  }

  ngOnInit(): void {
    this.languageSubscription = this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(selectedLanguage => {
      this.language = selectedLanguage;
    });

    this.role = this.commonService.getRole();

    this.options = [

      ...(this.role === roles.admin ? [
        {
          link: 'create-order',
          label: this.translations[this.language]?.createOrderLabel,
          icon: 'add',
          badge: 'create-order',
          id: 'create-order'
        }
      ] : []),

      // {
      //   link: 'dashboard',
      //   label: this.translations[this.language]?.dashboardLabel,
      //   icon: 'dashboard',
      //   badge: 'dashboard',
      //   id : 'dashboard'
      // },
      {
        link: 'orders',
        label: this.translations[this.language]?.ordersLabel,
        icon: 'orders',
        badge: 'orders',
        id: 'orders',
        queryParams: {
          refresh: true
        }
      },

      ...(this.role === roles.admin ? [
        {
          link: 'companies',
          label: this.translations[this.language]?.vendorsLabel,
          icon: 'vendors',
          badge: 'companies',
          id: 'companies'
        }
      ] : []),

      // {
      //   link: 'returns',
      //   label: this.translations[this.language]?.returnsLabel,
      //   icon: 'returns',
      //   badge: 'returns',
      //   id : 'returns'
      // },
      // {
      //   link: 'products',
      //   label: this.translations[this.language]?.productsLabel,
      //   icon: 'products',
      //   badge: 'products',
      //   id : 'products'
      // },
      // {
      //   link: 'customers',
      //   label: this.translations[this.language]?.customersLabel,
      //   icon: 'customers',
      //   badge: 'customers',
      //   id : 'customers'
      // },
      // {
      //   link: 'settings',
      //   label: this.translations[this.language]?.settingsLabel,
      //   icon: 'settings',
      //   badge: 'settings',
      //   id : 'settings'
      // }
      // {
      //   link: 'companies',
      //   label: this.translations[this.language]?.companiesLabel,
      //   icon: 'orders',
      //   badge: 'orders',
      //   id: 'companies',
      //   queryParams: {
      //     refresh: true
      //   }
      // },
    ];
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }
}
