import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {filter} from 'lodash';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as SharedSelectors from '../../../core/shared.selectors';
import * as fromRoot from '../../../../../state/app.state';
import {Translations} from '../../../../translations/shared-module/components/base/table.translation';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() data: any[];
  @Input() config: any[];
  @Input() totalRecordCount: number;
  @Input() pageNumber = 1;
  @Input() noDataMessage = 'No Data Available';
  @Output() handlePagination = new EventEmitter();
  @Output() handleSorting = new EventEmitter();

  showNoDataMessage = false;
  dataSource: any;
  displayedColumns: any;
  paginatorPageNumber: number;
  language: number;
  translations = {}
  languageSubscription: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
  ) {
    this.translations = Translations;
  }

  ngOnInit() {
    this.displayedColumns = this.config.map((eachObject) => eachObject.key);
    this.paginatorPageNumber = this.pageNumber;
    this.languageSubscription = this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(selectedLanguage => {
      this.language = selectedLanguage;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource = new MatTableDataSource(this.data);
    this.showNoDataMessage = changes.data && changes.data.currentValue.length === 0;
  }

  getNext(event) {
    this.handlePagination.emit({
      pageNumber: event.pageIndex + 1,
      limit: event.pageSize
    });
  }

  announceSortChange(event) {
    const sortDirection = event.direction === 'asc' ? 1 : event.direction === 'desc' ? -1 : 0;

    const sortColumnObject = (filter(this.config, (o) => o.key === event.active))[0];

    if (sortColumnObject) {
      this.handleSorting.emit({
        sortDirection,
        columnName: sortColumnObject.sortKey
      })
    }
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();

  }
}
