import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginPageService} from '../services/login-page.service';
import {Subscription} from 'rxjs';
import * as fromRoot from '../../../../../state/app.state';
import * as SharedActions from '../../../core/shared.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-login-page-container',
  templateUrl: './login-page-container.component.html',
  styleUrls: ['./login-page-container.component.css']
})
export class LoginPageContainerComponent implements OnInit, OnDestroy {
  loginSubscription: Subscription;

  constructor(private store: Store<fromRoot.State>,
              private loginServices: LoginPageService) {
    this.loginSubscription = this.loginServices.login.subscribe((payload) => {
      this.store.dispatch(SharedActions.LoginRequest({payload}));
    });
    this.store.dispatch(SharedActions.FetchLanguageFilterListRequest({}))
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.loginSubscription?.unsubscribe();
  }
}
