import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignUpPageService {

  signup = new Subject();

  constructor() {
  }
}
