<button
  (mousedown)="$event.preventDefault()"
  (throttledClick)="!isDisabled && handleClick.emit()"
  [disableRipple]="true"
  [ngClass]="{disabled: isDisabled}"
  [throttleTime]="1000"
  class="button primary-clickable-text"
  mat-button
  preventMultipleClick
>
  {{ text }}
</button>
