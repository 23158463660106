<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
>
  <mat-form-field
    [formGroup]="parentFormGroup"
    appearance="outline"
    class="form-field"
    hideRequiredMarker
  >
    <textarea
      #textarea
      [formControlName]="controlName"
      [maxlength]="maxLength"
      matInput
      placeholder="{{placeholder}}"
    >
    </textarea>
  </mat-form-field>
  <div
    *ngIf="error"
    class="form-field-error"
    fxLayoutAlign="start end"
  >
    {{error}}
  </div>
</div>
