/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[order details page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH ORDER DETAILS ***********/
const FETCH_ORDER_DETAILS_REQUEST = '[order details page] fetch order details requested';
export const FetchOrderDetailsRequest = createAction(FETCH_ORDER_DETAILS_REQUEST, props<any>());

const FETCH_ORDER_DETAILS_SUCCESS = '[order details page] fetch order details successful';
export const FetchOrderDetailsSuccess = createAction(FETCH_ORDER_DETAILS_SUCCESS, props<any>());

const FETCH_ORDER_DETAILS_FAILURE = '[order details page] fetch order details failure';
export const FetchOrderDetailsFailure = createAction(FETCH_ORDER_DETAILS_FAILURE, props<any>());

/******** UPDATE ORDER DETAILS ***********/
const UPDATE_ORDER_DETAILS_REQUEST = '[order details page] update order details requested';
export const UpdateOrderDetailsRequest = createAction(UPDATE_ORDER_DETAILS_REQUEST, props<any>());

const UPDATE_ORDER_DETAILS_SUCCESS = '[order details page] update order details successful';
export const UpdateOrderDetailsSuccess = createAction(UPDATE_ORDER_DETAILS_SUCCESS, props<any>());

const UPDATE_ORDER_DETAILS_FAILURE = '[order details page] update order details failure';
export const UpdateOrderDetailsFailure = createAction(UPDATE_ORDER_DETAILS_FAILURE, props<any>());

/******** ADMIN DELETE FILE ***********/
const ADMIN_DELETE_FILE_REQUEST = '[order details page] [Admin] Delete File Request';
export const AdminDeleteFileRequest = createAction(ADMIN_DELETE_FILE_REQUEST, props<any>());

const ADMIN_DELETE_FILE_SUCCESS = '[order details page] [Admin] Delete File successful';
export const AdminDeleteFileSuccess = createAction(ADMIN_DELETE_FILE_SUCCESS, props<any>());

const ADMIN_DELETE_FILE_FAILURE = '[order details page] [Admin] Delete File  failure';
export const AdminDeleteFileFailure = createAction(ADMIN_DELETE_FILE_FAILURE, props<any>());

