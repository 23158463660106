import {Component, OnDestroy, OnInit} from '@angular/core';
import * as SharedSelectors from '../../../../../core/shared.selectors';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../state/app.state';
import {Router} from '@angular/router';

@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.css']
})
export class TopNavBarComponent implements OnInit, OnDestroy {
  language$: Observable<any>
  languageSubscription: Subscription;
  languageFilterListSubscription: Subscription;
  selectedLanguage: number;
  languages = [];

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {

    this.language$ = this.store.pipe(select(SharedSelectors.getLanguage));

    this.languageSubscription = this.language$.subscribe(data => {
      this.selectedLanguage = data;
    });

    this.languageFilterListSubscription = this.store.pipe(select(SharedSelectors.getLanguageFilterList)).subscribe(data => {
      this.languages = data;
    })
  }

  goToLandingPage() {
    const roleName = this.router.url.split('/')[1];
    this.router.navigate([`${roleName}/orders`]);
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.languageFilterListSubscription?.unsubscribe();
  }

}
