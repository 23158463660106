import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {CommonService} from './modules/shared/services/common/common.service';
import * as fromRoot from './state/app.state';
import * as SharedSelectors from '../app/modules/shared/core/shared.selectors';
import * as SharedActions from '../app/modules/shared/core/shared.actions';
import {filter, pairwise, Subscription} from 'rxjs';
import {LoadingScreenComponent} from './modules/shared/components/base/loading-screen/loading-screen.component';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'tea-dashboard';

  isLoadingSubscription: Subscription;
  isLoadingScreenOpenSubscription: Subscription;
  routerEvent: Subscription;
  currentUrl: string;
  previousUrl: string;
  isLoadingScreenOpen = false;
  language: number;
  languageSubscription: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
      const previousUrl = e[0].urlAfterRedirects
      this.store.dispatch(SharedActions.SetPreviousUrl({previousUrl}));
    });
  }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.store.dispatch(SharedActions.FetchLanguageFilterListRequest({}))

    this.route.queryParams.subscribe(params => {
      if (params.token) {
        this.commonService.setAuthenticationToken(params.token);
      }

      if (params.language) {
        this.commonService.setLanguage(Number(params.language), true);
      }
    });

    this.isLoadingScreenOpenSubscription = this.commonService.isLoadingScreenOpen.subscribe(data => {
      this.isLoadingScreenOpen = data;
    });

    this.isLoadingSubscription = this.store.pipe(select(SharedSelectors.isLoading)).subscribe(data => {
      if (!!data.length && !this.isLoadingScreenOpen) {
        this.commonService.isLoadingScreenOpen.next(true);
        this.dialog.open(LoadingScreenComponent, {
          backdropClass: 'spinner-backdrop',
          panelClass: 'spinner-panel'
        });
      }
    });

    this.languageSubscription = this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(selectedLanguage => {
      this.language = selectedLanguage;
      const body = document.getElementsByTagName('body')[0];
      const langMap = {
        1: 'en',
        2: 'ja'
      }

      for (let i = 0; i < body.classList?.length; i++) {
        if (body.classList[i]?.includes('lang-')) {
          body.classList.remove(body.classList[i])
          break;
        }
      }

      body.classList.add(`lang-${langMap[this.language]}`);
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription?.unsubscribe();
    this.routerEvent?.unsubscribe();
    this.languageSubscription?.unsubscribe();
  }
}
