import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CommonService} from '../common/common.service';
import {roles} from '../../constants/base.constants.js';


@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

  constructor(
    private router: Router,
    private commonService: CommonService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const role = this.commonService.getRole(); // ... your authentication logic here
    if (role === roles.admin) {
      return true;
    } else {
      this.commonService.logout();
      return false;
    }
  }
}
