import {Component, OnInit} from '@angular/core';
import {Translations} from '../../../../translations/shared-module/components/base/page-not-found.translation';
import {Subscription} from 'rxjs';
import * as SharedSelectors from '../../../core/shared.selectors';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './internal-page-not-found.component.html',
  styleUrls: ['./internal-page-not-found.component.css']
})
export class InternalPageNotFoundComponent implements OnInit {
  translations: any;
  languageSubscription: Subscription;

  language: number;

  constructor(
    private store: Store<fromRoot.State>
  ) {
    this.translations = Translations;
  }

  ngOnInit(): void {
    this.languageSubscription = this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(selectedLanguage => {
      this.language = selectedLanguage;
    });
  }

}
