interface EffectsTranslations {
  unauthorizedAccess: string,
}

const en: EffectsTranslations = {
  unauthorizedAccess: 'Unauthorized Access.',
}

const ja: EffectsTranslations = {
  unauthorizedAccess: '未承認のアクセス',
}

export const Translations = {
  1: en,
  2: ja
}
