/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SharedState} from './shared.reducer';

// Selector functions
const SharedState = createFeatureSelector<SharedState>('shared');

export const isLoading = createSelector(
  SharedState,
  state => state.loading
);

export const getLanguage = createSelector(
  SharedState,
  (state) => state.language
);

export const getCountryCode = createSelector(
  SharedState,
  (state) => state.countryCode
);

export const getProfile = createSelector(
  SharedState,
  (state: SharedState) => state.profile
);

export const getUserRolesFilterList = createSelector(
  SharedState,
  (state) => state.userRolesFilterList
);

export const getCompanyFilterList = createSelector(
  SharedState,
  (state) => state.companyFilterList
);

export const getCountriesList = createSelector(
  SharedState,
  (state) => state.countriesList
);

export const getPhoneCodesList = createSelector(
  SharedState,
  (state) => state.phoneCodesList
);

export const getOrderStatusList = createSelector(
  SharedState,
  (state) => state.orderStatusList
);

export const getLanguageFilterList = createSelector(
  SharedState,
  (state) => state.languageFilterList
);

export const getPreviousUrl = createSelector(
  SharedState,
  (state) => state.previousUrl
);
