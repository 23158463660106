import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../services/common/common.service';
import {Router} from '@angular/router';
import {SendInvitationPageService} from '../../services/send-invitation-page.service';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../state/app.state';
import * as SharedSelectors from '../../../../core/shared.selectors';
import {roles} from '../../../../constants/base.constants.js';
import {Translations} from '../../../../../translations/shared-module/components/send-invitation-form.translation';

@Component({
  selector: 'app-send-invitation-form',
  templateUrl: './send-invitation-form.component.html',
  styleUrls: ['./send-invitation-form.component.css']
})
export class SendInvitationFormComponent implements OnInit, OnDestroy {

  roleIdSubscription: Subscription;
  userRolesFilterListSubscription: Subscription;
  companyFilterListSubscription: Subscription;
  languageSubscription: Subscription;
  clearSendInviteFormSubscription: Subscription;

  translations: any = {}
  language: number;
  form: FormGroup;
  singleSelectOptions: any;
  singleSelectNameOptions: any;
  errorMessageMap: any = {};
  roles: any;

  errorMessages: any = {};

  constructor(private sendInvitationService: SendInvitationPageService,
              private fb: FormBuilder,
              private commonService: CommonService,
              private router: Router,
              private store: Store<fromRoot.State>) {
    this.translations = Translations;
    this.roles = roles;
  }

  ngOnInit() {

    this.form = this.fb.group({
      roleId: new FormControl({value: '', disabled: false}, [Validators.required]),
      companyId: new FormControl({value: '', disabled: false}, [Validators.required]),
      email: new FormControl({value: '', disabled: false}, [
        Validators.required,
        Validators.pattern(this.commonService.emailRegex)
      ]),
    });

    this.languageSubscription = this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(selectedLanguage => {
      this.language = selectedLanguage;
      this.errorMessageMap = this.translations[this.language].errorMessageMap;
    });

    this.userRolesFilterListSubscription = this.store.pipe(select(SharedSelectors.getUserRolesFilterList)).subscribe((data) => {
      this.singleSelectOptions = data;
    });

    this.companyFilterListSubscription = this.store.pipe(select(SharedSelectors.getCompanyFilterList)).subscribe((data) => {
      this.singleSelectNameOptions = data;
    });

    this.roleIdSubscription = this.form.controls.roleId.valueChanges.subscribe(role => {
      if (role !== this.roles.inventoryPartner) {
        this.form.controls.companyId.disable();
        this.form.patchValue({companyId: ''}, {emitEvent: false});
      } else {
        this.form.controls.companyId.enable();
      }
    });

    this.clearSendInviteFormSubscription = this.commonService.clearSendInviteForm.subscribe(() => {
      if (this.form) {
        this.form.reset();
      }
    });
  }

  sendInvite(): void {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const {roleId, companyId, email} = this.form.getRawValue();
    const payload = {
      role: roleId,
      ...(companyId ? {companyId} : {}),
      email
    };

    this.sendInvitationService.invite.emit(payload);
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.userRolesFilterListSubscription?.unsubscribe();
    this.companyFilterListSubscription?.unsubscribe();
    this.roleIdSubscription?.unsubscribe();
  }
}
