import {Injectable} from '@angular/core';
/* NgRx */
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SharedService} from './shared.service';
import * as fromRoot from '../../../state/app.state';
import {CommonService} from '../services/common/common.service';
import {Observable, of} from 'rxjs';
import * as SharedActions from './shared.actions';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {allowedInvitationRoles, roles} from '../constants/base.constants.js';
import {Translations} from '../../translations/shared-module/core/effects.translations';

@Injectable()
export class SharedEffects {
  language = this.commonService.getLanguage();

  invite$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.SendInvitationRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.sendInvitation(payload).pipe(
          map((response: any) => {
            return SharedActions.SendInvitationSuccess({
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(SharedActions.SendInvitationFailure(error.message));
          }),
          tap((action) => {
            if (action.type === SharedActions.SendInvitationSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success')
              this.commonService.clearSendInviteForm.next(null);
            } else if (action.type === SharedActions.SendInvitationFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  userRolesFilterList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.FetchUserRolesFilterListRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchUserRolesList().pipe(
          map((response: any) => {
            const {data} = response;

            return SharedActions.FetchUserRolesFilterListSuccess({
              message: response.message,
              userRolesFilterList: data.filter(option => allowedInvitationRoles.includes(option.key))
            });
          }),
          catchError((error) => {
            return of(SharedActions.FetchUserRolesFilterListFailure(error.message));
          }),
          tap((action) => {
            if (action.type === SharedActions.FetchUserRolesFilterListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === SharedActions.FetchUserRolesFilterListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        ))
    ));

  companyFilterList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.FetchCompanyFilterListRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchCompanyList().pipe(
          map((response: any) => {
            const {data} = response;
            return SharedActions.FetchCompanyFilterListSuccess({
              message: response.message,
              companyFilterList: data
            });
          }),
          catchError((error) => {
            return of(SharedActions.FetchCompanyFilterListFailure(error.message));
          }),
          tap((action) => {
            if (action.type === SharedActions.FetchCompanyFilterListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === SharedActions.FetchCompanyFilterListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        ))
    ));

  login$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.LoginRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.login(payload).pipe(
          map((response: any) => {
            const {data} = response;
            const token = data.token;
            this.commonService.setAuthenticationToken(token);
            const userProfile = {
              fullName: data.profile.fullName,
              email: data.profile.email,
              gender: data.profile.gender,
              age: data.profile.age,
              role: data.profile.role,
              image: data.profile.image,
              roleName: data.profile.roleName
            };

            const validRoles = [roles.admin, roles.inventoryPartner];

            if (!validRoles.includes(userProfile.role)) {
              throw ({message: Translations[this.language].unauthorizedAccess});
            }

            return SharedActions.LoginSuccess({
              userProfile,
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(SharedActions.LoginFailure({message: error.message}));
          }),
          tap((action) => {
            if (action.type === SharedActions.LoginSuccess.type) {
              // Code to execute on API Success Action dispatch
              if (action.userProfile.role === roles.admin) {
                this.router.navigateByUrl('/admin');
              } else if (action.userProfile.role === roles.inventoryPartner) {
                this.router.navigateByUrl('/inventory-partner');
              }
            } else if (action.type === SharedActions.LoginFailure.type) {
              // Code to execute on API Failure Action dispatch
              this.commonService.notification(
                action.message,
                'danger'
              );
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  logout$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.LogoutRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.logout(payload).pipe(
          map(() => {
            return SharedActions.LogoutSuccess('Logout Successful.');
          }),
          catchError((error) => {
            return of(SharedActions.LogoutFailure(error.message));
          }),
          tap((action) => {
            if (action.type === SharedActions.LogoutSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.router.navigate(['/login']).then(() => {
                this.commonService.clearAppState();
                this.commonService.clearStorage();
              });
            } else if (action.type === SharedActions.LogoutFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  signup$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.SignUpRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.signup(payload).pipe(
          map((response: any) => {
            const {data} = response;
            const token = data.token;
            this.commonService.setAuthenticationToken(token);
            const userProfile = {
              fullName: data.profile.fullName,
              email: data.profile.email,
              gender: data.profile.gender,
              age: data.profile.age,
              role: data.profile.role,
              image: data.profile.image,
              roleName: data.profile.roleName
            };

            const validRoles = [roles.admin, roles.inventoryPartner];

            if (!validRoles.includes(userProfile.role)) {
              throw ({message: 'Valid token not returned'});
            }

            return SharedActions.SignUpSuccess({
              userProfile,
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(SharedActions.SignUpFailure({message: error.message}));
          }),
          tap((action) => {
            if (action.type === SharedActions.SignUpSuccess.type) {
              // Code to execute on API Success Action dispatch
              if (action.userProfile.role === roles.admin) {
                this.router.navigateByUrl('/admin');
              } else if (action.userProfile.role === roles.inventoryPartner) {
                this.router.navigateByUrl('/inventory-partner');
              }
            } else if (action.type === SharedActions.SignUpFailure.type) {
              // Code to execute on API Failure Action dispatch
              this.commonService.notification(
                action.message,
                'danger'
              );
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  fetchCountriesList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.FetchCountriesListRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(() =>
        this.sharedService.fetchCountries().pipe(
          map((response: any) => {
            const countries = response.data;

            let countriesList = [];
            let phoneCodesList = [];

            countries.forEach((eachCountry) => {
              const phoneCode = eachCountry.phoneCode;
              const countryName = eachCountry.value;
              const countryId = eachCountry.key;
              countriesList = [
                ...countriesList,
                {
                  key: phoneCode + '_' + countryId,
                  value: countryName,
                  isoCode: eachCountry.isoCode,
                  currencySymbol: eachCountry.currencySymbol,
                },
              ];

              phoneCodesList = [
                ...phoneCodesList,
                {
                  key: phoneCode + '_' + countryId,
                  value: phoneCode,
                },
              ];
            });

            return SharedActions.FetchCountriesListSuccess({
              countriesList,
              phoneCodesList,
            });
          }),
          catchError((error) => {
            return of(SharedActions.FetchCountriesListFailure(error.message));
          }),
          tap((action) => {
            if (action.type === SharedActions.FetchCountriesListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (
              action.type === SharedActions.FetchCountriesListFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  fetchOrderStatusList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.FetchOrderStatusListRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(() =>
        this.sharedService.fetchOrderStatusList().pipe(
          map((response: any) => {
            const status = response.data;
            let orderStatusList: any = [];
            status.forEach((eachStatus) => {
              const orderStatusCode = eachStatus.value;
              const orderStatus = eachStatus.key;
              orderStatusList = [
                ...orderStatusList,
                {
                  key: orderStatus,
                  value: orderStatusCode,
                },
              ];
            });
            return SharedActions.FetchOrderStatusListSuccess({
              message: response.message,
              orderStatusList
            });
          }),
          catchError((error) => {
            return of(SharedActions.FetchOrderStatusListFailure(error.message));
          }),
          tap((action) => {
            if (action.type === SharedActions.FetchOrderStatusListSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (
              action.type === SharedActions.FetchOrderStatusListFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  fetchProfile$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.FetchProfileRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchProfile().pipe(
          map((response: any) => {
            const {data} = response;
            const userProfile = {
              fullName: data.fullName,
              email: data.email,
              gender: data.gender,
              age: data.age,
              role: data.role,
              image: data.image,
              roleName: data.roleName
            };
            return SharedActions.FetchProfileSuccess({
              userProfile,
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(SharedActions.FetchProfileFailure(error.message));
          }),
          tap((action) => {
            if (action.type === SharedActions.FetchProfileSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === SharedActions.FetchProfileFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  fetchLanguageList$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(SharedActions.FetchLanguageFilterListRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.sharedService.fetchLanguageList().pipe(
          map((response: any) => {
            const {data} = response;
            const languageFilterList = data
            return SharedActions.FetchLanguageFilterListSuccess({
              languageFilterList,
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(SharedActions.FetchLanguageFilterListFailure({message: error.message}));
          }),
          tap((action) => {
            if (action.type === SharedActions.FetchLanguageFilterListSuccess.type) {
              // Code to execute on API Success Action dispatch
              let currentUrl = this.router.url;
              currentUrl = currentUrl.split('/').pop();
              if (currentUrl === 'maintenance') {
                this.router.navigate([this.commonService.getPreviousUrl()])
              }
            } else if (action.type === SharedActions.FetchLanguageFilterListFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );


  constructor(private store: Store<fromRoot.State>,
              private commonService: CommonService,
              private sharedService: SharedService,
              private actions: Actions,
              private router: Router,
  ) {
  }
}
