import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sign-up-page-screen',
  templateUrl: './sign-up-page-screen.component.html',
  styleUrls: ['./sign-up-page-screen.component.css']
})
export class SignUpPageScreenComponent implements OnInit {

  @Input() email;

  constructor() {
  }

  ngOnInit(): void {
  }

}
