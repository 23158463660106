export const roles = {
  admin: 1,
  inventoryPartner: 4
};

export const allowedInvitationRoles = [1, 4];

export const orderStatus = {
  paymentPending: 0,
  paymentFailed: 1,
  orderPlaced: 2,
  processing: 3,
  packed: 4,
  shipped: 5,
  cancelled: 6,
  receivedByUser: 7,
  returnInitiated: 8,
  returnShipped: 9,
  returnReceived: 10,
  refundInitiated: 11,
  refundSuccessful: 12,
  refundFailed: 13,
}

export const language = {
  english: 1,
  japanese: 2
};
