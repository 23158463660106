import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-label-type-five',
  templateUrl: './label-type-five.component.html',
  styleUrls: ['./label-type-five.component.css']
})
export class LabelTypeFiveComponent implements OnInit {
  @Input() label: string;
  @Input() asteriskSymbol: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
