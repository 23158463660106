import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DatetimeAdapter, MAT_DATETIME_FORMATS} from '@mat-datetimepicker/core';
import {MomentDatetimeAdapter} from '@mat-datetimepicker/moment';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-date-time-picker-type-two',
  templateUrl: './date-time-picker-type-two.component.html',
  styleUrls: ['./date-time-picker-type-two.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    },
    {
      provide: DatetimeAdapter,
      useClass: MomentDatetimeAdapter
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false
      }
    },
    {
      provide: MAT_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: 'LL LT',
          datetimeInput: 'L LT'
        },
        display: {
          dateInput: 'MM/DD/YYYY, LT',
          datetimeInput: 'MM/DD/YYYY, LT',
          popupHeaderDateLabel: 'ddd, DD MMM'
        }
      }
    }
  ]
})
export class DateTimePickerTypeTwoComponent implements OnInit, OnChanges {
  @ViewChild('input') input: ElementRef;

  @Input() focus: boolean;
  @Input() label: string;
  @Input() showLabel: boolean;
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.focus?.currentValue) {
      const yOffset = -25;
      const y = this.input?.nativeElement.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }

  ngOnInit() {
  }
}
