<div class="screen">
  <div class="top-nav-bar">
    <app-top-nav-bar></app-top-nav-bar>
  </div>
  <mat-drawer-container class="page-content">
    <mat-drawer
      class="sidenav-list"
      mode="side"
      opened
    >
      <app-side-nav-list></app-side-nav-list>
    </mat-drawer>
    <mat-drawer-content
      class="sidenav-content"
    >
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
