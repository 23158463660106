import {Component, OnInit} from '@angular/core';
import * as SharedActions from '../../../core/shared.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';

@Component({
  selector: 'app-authenticated-wrapper',
  templateUrl: './authenticated-wrapper.component.html',
  styleUrls: ['./authenticated-wrapper.component.css']
})
export class AuthenticatedWrapperComponent implements OnInit {

  constructor(
    private store: Store<fromRoot.State>
  ) {
    this.store.dispatch(SharedActions.FetchProfileRequest({}));
  }

  ngOnInit(): void {
  }

}
