import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonService} from '../../../../../../services/common/common.service';
import {Subscription} from 'rxjs';
import * as SharedSelectors from '../../../../../../core/shared.selectors';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../../state/app.state';
import {
  SendInvitationContainerComponent
} from '../../../../../send-invitation/send-invitation-container/send-invitation-container.component';
import {
  Translations
} from '../../../../../../../translations/shared-module/authenticated-wrapper/top-nav-bar/top-nav-bar.translation';
import {roles} from '../../../../../../constants/base.constants.js';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.css']
})
export class ProfileMenuComponent implements OnInit, OnDestroy {
  profileData: any;
  language: number;
  toggle: boolean
  config: any [];

  profileSubscription: Subscription;
  languageSubscription: Subscription;

  icon = 'caret-down';
  translations = {}

  constructor(
    public commonService: CommonService,
    private store: Store<fromRoot.State>
  ) {
    this.translations = Translations;
  }

  ngOnInit(): void {
    this.profileSubscription = this.store.pipe(select(SharedSelectors.getProfile)).subscribe(data => {
      this.profileData = data;
    });

    this.languageSubscription = this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(selectedLanguage => {
      this.language = selectedLanguage;
    });

    this.config = [
      ...((this.commonService.getRole() === roles.admin) ? [{
        name: this.translations[this.language]?.sendInvitation,
        handleClick: () => this.openSendInvitationDialogBox(),
        id: 'send_invitation'
      }] : []),
      {
        name: this.translations[this.language]?.logout,
        handleClick: () => this.commonService.openConfirmationDialog({
          data: {
            message: this.translations[this.language]?.logoutMessage,
            submitText: this.translations[this.language]?.logoutSubmitText,
            handleSubmit: () => this.commonService.closeDialog(),
            cancelText: this.translations[this.language]?.logoutCancelText,
            handleCancel: () => this.commonService.logout()
          }
        }),
        id: 'logout'
      }
    ]
  }

  isOpened() {
    this.icon = 'caret-up'
  }

  isClosed() {
    this.icon = 'caret-down'
  }

  openSendInvitationDialogBox() {
    this.commonService.openDialog({data: {component: SendInvitationContainerComponent}});
  }

  ngOnDestroy() {
    this.profileSubscription?.unsubscribe()
  }
}
