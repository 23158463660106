<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
  ngClass.gt-lg="xl"
>
  <mat-form-field
    [formGroup]="parentFormGroup"
    [ngClass]="{
      'has-prefix': !!prefixIcon,
      'has-suffix': !!suffixIcon
    }"
    appearance="outline"
    class="form-field"
  >
    <mat-label class="ja-heading">{{label}}</mat-label>
    <input
      #input
      [formControlName]="controlName"
      [maxlength]="maxLength"
      [ngClass]="{
      'hasPrefix': !!prefixIcon,
      'hasSuffix': !!suffixIcon
    }"
      matInput
      phoneNumber
      placeholder="{{placeholder}}"
      type="text"
    >
    <mat-icon
      *ngIf="prefixIcon"
      class="form-field-icon prefix-icon"
      fxLayoutAlign="center center"
      matPrefix
    >
      {{prefixIcon}}
    </mat-icon>
    <mat-icon
      *ngIf="suffixIcon"
      class="form-field-icon suffix-icon"
      fxLayoutAlign="center center"
      matSuffix
    >
      {{suffixIcon}}
    </mat-icon>
  </mat-form-field>
  <div
    *ngIf="error"
    class="form-field-error"
    fxLayoutAlign="start end"
  >
    {{error}}
  </div>
</div>
