import {Component, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.css']
})
export class BasicDialogComponent implements OnInit {
  @ViewChild('anchor', {static: true, read: ViewContainerRef}) anchor;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BasicDialogComponent>
  ) {
  }

  ngOnInit(): void {
    this.anchor.clear();
    const {component} = this.data;
    delete this.data?.component;
    const componentRef = this.anchor.createComponent(component);
    componentRef.instance.data = this.data;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
