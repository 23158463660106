interface PageUnderMaintenanceTranslation {
  errorMessage: string;
  message: string;
}

const en: PageUnderMaintenanceTranslation = {
  errorMessage: 'WE’RE DOWN FOR MAINTAINANCE',
  message: 'This page is undergoing maintainance and will be back soon',
}

const ja: PageUnderMaintenanceTranslation = {
  errorMessage: 'メンテナンス中です',
  message: 'メンテナンス中です',
}

export const Translations = {
  1: en,
  2: ja
}
