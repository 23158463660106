import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as SharedActions from '../../../core/shared.actions'
import {SendInvitationPageService} from '../services/send-invitation-page.service';
import * as fromRoot from '../../../../../state/app.state';

@Component({
  selector: 'app-send-invitation-container',
  templateUrl: './send-invitation-container.component.html',
  styleUrls: ['./send-invitation-container.component.css']
})
export class SendInvitationContainerComponent implements OnInit, OnDestroy {
  sendInvitationPageSubscription: Subscription;

  constructor(private store: Store<fromRoot.State>,
              private sendInvitationService: SendInvitationPageService) {
    this.sendInvitationPageSubscription = this.sendInvitationService.invite.subscribe((payload) => {
      this.store.dispatch(SharedActions.SendInvitationRequest({payload}));
    });
    this.store.dispatch(SharedActions.FetchUserRolesFilterListRequest({}));
    this.store.dispatch(SharedActions.FetchCompanyFilterListRequest({}));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.sendInvitationPageSubscription?.unsubscribe();
  }

}
