import {environment} from '../../../../environments/environment';

const baseURL = environment.apiURL;

const v1Base = `${baseURL}/api/v1`

export const v1URL = {
  user: {
    login: {
      url: `${v1Base}/user/login`,
      method: 'post'
    },

    logout: {
      url: `${v1Base}/user/logout`,
      method: 'post'
    },

    profile: {
      fetch: {
        url: `${v1Base}/user/profile/fetch`,
        method: 'post'
      }
    }
  },

  dashboard: {
    admin: {

      order: {
        fetch: {
          url: `${v1Base}/dashboard/admin/order/fetch`,
          method: 'post'
        },

        update: {
          url: `${v1Base}/dashboard/admin/order/update`,
          method: 'post'
        },

        summary: {
          count: {
            url: `${v1Base}/dashboard/admin/order/summary/count`,
            method: 'post'
          }
        },

        fileUploadUrl: {
          fetch: {
            url: `${v1Base}/dashboard/admin/order/fileUploadUrl/fetch`,
            method: 'post'
          }
        },

        file: {
          delete: {
            url: `${v1Base}/dashboard/admin/order/file/delete`,
            method: 'post'
          }
        },
        notification: {
          send: {
            url: `${v1Base}/dashboard/admin/order/notification/send`,
            method: 'post'
          }
        },

        place: {
          url: `${v1Base}/dashboard/admin/order/place`,
          method: 'post'
        },

        email: {
          emailStatusFetch: {
            url: `${v1Base}/dashboard/admin/order/email/status/fetch`,
            method: 'post'
          },

          sendEmail: {
            url: `${v1Base}/dashboard/admin/order/email/send`,
            method: 'post'
          }
        },
      },

      invite: {
        invite: {
          url: `${v1Base}/dashboard/admin/invite`,
          method: 'post'
        }
      },
      company: {
        fetch: {
          url: `${v1Base}/dashboard/admin/company/fetch`,
          method: 'post'
        },

        add: {
          url: `${v1Base}/dashboard/admin/company/add`,
          method: 'post'
        },

        remove: {
          url: `${v1Base}/dashboard/admin/company/remove`,
          method: 'post'
        },

        logoUploadUrl: {
          fetch: {
            url: `${v1Base}/dashboard/admin/company/logoUploadUrl/fetch`,
            method: 'post'
          }
        },
        logo: {
          delete: {
            url: `${v1Base}/dashboard/admin/company/logo/delete`,
            method: 'post'
          }
        }
      },
    },

    inventoryPartner: {

      order: {
        fetch: {
          url: `${v1Base}/dashboard/inventoryPartner/order/fetch`,
          method: 'post'
        },

        update: {
          url: `${v1Base}/dashboard/inventoryPartner/order/update`,
          method: 'post'
        },

        summary: {
          count: {
            url: `${v1Base}/dashboard/inventoryPartner/order/summary/count`,
            method: 'post'
          }
        }
      }
    },

    common: {
      invite: {
        accept: {
          url: `${v1Base}/dashboard/common/invite/accept`,
          method: 'post'
        }
      }
    }
  },

  product: {
    fetch: {
      url: `${v1Base}/product/fetch`,
      method: 'post'
    },
  },

  filter: {
    userRoles: {
      fetch: {
        url: `${v1Base}/filter/userRoles/fetch`,
        method: 'post'
      }
    },

    company: {
      fetch: {
        url: `${v1Base}/filter/company/fetch`,
        method: 'post'
      }
    },

    countries: {
      fetch: {
        url: `${v1Base}/filter/countries/fetch`,
        method: 'post'
      }
    },

    orderStatus: {
      fetch: {
        url: `${v1Base}/filter/orderStatus/fetch`,
        method: 'post'
      }
    },

    language: {
      fetch: {
        url: `${v1Base}/filter/language/fetch`,
        method: 'post'
      }
    },

  },
}
