interface LoginFormTranslation {
  heading: string,
  emailLabel: string,
  emailPlaceholder: string,
  passwordLabel: string,
  passwordPlaceholder: string,
  loginButton: string,
  errorMessageMap: object
}

const en: LoginFormTranslation = {
  heading: 'Login',
  emailLabel: 'Email Address',
  emailPlaceholder: 'Email Address',
  passwordLabel: 'Password',
  passwordPlaceholder: 'Password',
  loginButton: 'Login',
  errorMessageMap: {
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email.'
    },
    password: {
      required: 'This field is required.',
      minlength: 'Password must contain at least 8 characters',
      maxlength: 'Password must contain only 20 characters',
      lowerCaseCheckFailed: 'Password must contain at least 1 Lowercase character',
      upperCaseCheckFailed: 'Password must contain at least 1 Uppercase character',
      numberCheckFailed: 'Password must contain at least 1 numeric character',
      specialCharCheckFailed: 'Password must contain a Special character',
    },
  }
}

const ja: LoginFormTranslation = {
  heading: 'ログイン',
  emailLabel: 'メールアドレス',
  emailPlaceholder: 'メールアドレス',
  passwordLabel: 'パスワード',
  passwordPlaceholder: 'パスワード',
  loginButton: 'ログイン',
  errorMessageMap: {
    email: {
      required: '入力が必須な項目です',
      pattern: '無効なメールアドレス'
    },
    password: {
      required: '入力が必須な項目です',
      minlength: 'パスワードは半角で8文字以上で設定してください',
      maxlength: 'パスワードは半角で20文字以内で設定してください',
      lowerCaseCheckFailed: 'パスワードは1文字以上の半角小文字の英語を入れてください',
      upperCaseCheckFailed: 'パスワードは1文字以上の半角大文字の英語を入れてください',
      numberCheckFailed: 'パスワードは1文字以上の半角数字を入れてください',
      specialCharCheckFailed: 'パスワードは1文字以上の半角特殊文字(&や@等)を入れてください',
    },
  }
}

export const Translations = {
  1: en,
  2: ja
}
