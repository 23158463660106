interface TopNavBarTranslation {
  sendInvitation: string,
  logout: string,
  logoutMessage: string,
  logoutSubmitText: string,
  logoutCancelText: string,
}

const en: TopNavBarTranslation = {
  sendInvitation: 'Send Invitation',
  logout: 'Logout',
  logoutMessage: 'Are you sure you want to logout?',
  logoutSubmitText: 'Cancel',
  logoutCancelText: 'Logout',
}

const ja: TopNavBarTranslation = {
  sendInvitation: '招待を送信',
  logout: 'ログアウト',
  logoutMessage: '<span class="ja-heading">ログアウトしますか？</span>',
  logoutSubmitText: 'キャンセル',
  logoutCancelText: 'ログアウト',
}

export const Translations = {
  1: en,
  2: ja
}
