<button
  (mousedown)="$event.preventDefault()"
  (throttledClick)="!isDisabled && handleClick.emit()"
  [disableRipple]="isDisabled"
  [ngClass]="{disabled: isDisabled}"
  [throttleTime]="1000"
  class="button primary-button "
  mat-button
  preventMultipleClick
>
  <div class="button-content"
       fxLayout="row"
       fxLayoutAlign="center center"
  >
    <div
      *ngIf="prefixIcon"
      class="prefix-icon"
      fxLayoutAlign="center center"
    >
      <mat-icon>
        {{prefixIcon}}
      </mat-icon>
    </div>
    <div class="button-label ja-heading">
      {{ text }}
    </div>
  </div>
</button>
