interface SideNavListTranslation {
  createOrderLabel: string,
  dashboardLabel: string,
  ordersLabel: string,
  returnsLabel: string,
  productsLabel: string,
  customersLabel: string,
  settingsLabel: string,
  vendorsLabel: string,
}

const en: SideNavListTranslation = {
  createOrderLabel: 'Create Order',
  dashboardLabel: 'Dashboard',
  ordersLabel: 'Orders',
  returnsLabel: 'Returns',
  productsLabel: 'Products',
  customersLabel: 'Customers',
  settingsLabel: 'Settings',
  vendorsLabel: 'Vendors',
}

const ja: SideNavListTranslation = {
  createOrderLabel: '注文を作成',
  dashboardLabel: 'ダッシュボード',
  ordersLabel: '注文',
  returnsLabel: '返品済',
  productsLabel: '商品',
  customersLabel: 'お客様',
  settingsLabel: '設定',
  vendorsLabel: '商品ブランド'
}

export const Translations = {
  1: en,
  2: ja
}
