<div
  class="signup-form-component"
  fxLayoutAlign="center center"
  ngClass.gt-lg="xl"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
>
  <div
    class="signup-form-div"
    fxFlex="60"
    fxLayout="column"
  >
    <div
      class="heading ja-heading"
      fxLayoutAlign="center center"
    >
      {{translations[language]?.heading}}
    </div>
    <div
      *ngIf="companyName"
      class="company-name "
      fxLayoutAlign="center center"
    >
      {{companyName}}
    </div>
    <form
      [formGroup]="form"
      class="signup-form"
      fxLayout="column"
      fxLayoutGap="1.2rem"
    >
      <app-text-input
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'name'"
        [error]="errorMessages['name']"
        [focus]="focus['name']"
        [label]="translations[language]?.nameLabel"
        [parentFormGroup]="form"
        [placeholder]="translations[language]?.namePlaceholder"
        class="field-row username"
        id="name"
        maxLength=25
        minLength=1
      >
      </app-text-input>
      <app-text-input
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'email'"
        [error]="errorMessages['email']"
        [focus]="focus['email']"
        [label]="translations[language]?.emailLabel"
        [parentFormGroup]="form"
        [placeholder]="translations[language]?.emailPlaceholder"
        class="field-row username"
        id="email_address"
        maxLength=100
      >
      </app-text-input>
      <app-password-input
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'password'"
        [error]="errorMessages['password']"
        [focus]="focus['password']"
        [label]="translations[language]?.passwordLabel"
        [parentFormGroup]="form"
        [placeholder]="translations[language]?.passwordPlaceholder"
        class="field-row password"
        id="password"
        maxLength=20
      >
      </app-password-input>
      <app-password-input
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'confirmPassword'"
        [error]="errorMessages['confirmPassword']"
        [focus]="focus['confirmPassword']"
        [label]="translations[language]?.confirmPasswordLabel"
        [parentFormGroup]="form"
        [placeholder]="translations[language]?.confirmPasswordPlaceholder"
        class="field-row password"
        id="confirm_password"
        maxLength=20
      >
      </app-password-input>
      <app-primary-button
        (handleClick)="signup()"
        [isDisabled]="!enableForm"
        id="sign_up_button"
        text="{{translations[language]?.signUpButton}}"
      ></app-primary-button>
    </form>
    <div
      (click)="redirectToLogin()"
      class="redirect-to-login"
      fxLayoutAlign="center center"
      id="redirect_to_login"
    >
      <span class="redirect_to_login-text">{{translations[language]?.redirectToLogin}}</span>&nbsp;
      <span class="login">{{translations[language]?.login}}</span>
    </div>
    <app-language-dropdown
      [languages]="languages"
      [selectedLanguage]="language">
    </app-language-dropdown>
  </div>
</div>
