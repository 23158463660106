<div>
	<table
		(matSortChange)="announceSortChange($event)"
		*ngIf="dataSource"
		[dataSource]="dataSource"
		mat-table
		matSort
	>
		<div *ngFor="let eachColumn of config">
			<!-- CLICK -->
			<ng-container
				*ngIf="eachColumn.type === 'click'"
				[matColumnDef]="eachColumn.key"
			>
				<th
					*matHeaderCellDef
					[id]="'th_'+eachColumn.key"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="click-heading"
					mat-header-cell
					mat-sort-header
				>
					<div
						class="parent-cell ja-heading"
						fxLayoutAlign="start center"
					>
						{{eachColumn.displayName}}
					</div>
				</th>

				<td
					*matCellDef="let row"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="click-cell"
					mat-cell
				>
					<div
						(click)="eachColumn.handleClick && eachColumn.handleClick(row)"
						[ngStyle]="eachColumn.style"
						class="parent-cell ff-lang-ja-text-en"
						fxLayoutAlign="start center"
					>
						{{row[eachColumn.key]}}
					</div>
				</td>
			</ng-container>

			<!-- STRING -->
			<ng-container
				*ngIf="eachColumn.type === 'string'"
				[matColumnDef]="eachColumn.key"
			>
				<th
					*matHeaderCellDef
					[id]="'th_'+eachColumn.key"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="string-heading"
					mat-header-cell
					mat-sort-header
				>
					<div
						class="parent-cell ja-heading"
						fxLayoutAlign="start center"
					>
						{{eachColumn.displayName}}
					</div>

				</th>
				<td
					*matCellDef="let row"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="string-cell "
					mat-cell
				>
					<div
						class="parent-cell"
						fxLayoutAlign="start center"
					>
						<div class="string-cell-content">
							{{row[eachColumn.key]| titlecase}}
						</div>
					</div>

				</td>
			</ng-container>

			<!-- DATE -->
			<ng-container
				*ngIf="eachColumn.type === 'date'"
				[matColumnDef]="eachColumn.key"
			>
				<th
					*matHeaderCellDef
					[id]="'th_'+eachColumn.key"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="date-heading"
					mat-header-cell
					mat-sort-header
				>
					<div
						class="parent-cell ja-heading"
						fxLayoutAlign="start center"
					>
						{{eachColumn.displayName}}
					</div>
				</th>

				<td
					*matCellDef="let row"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="date-cell"
					mat-cell
				>
					<div
						class="parent-cell ff-lang-ja-text-en"
						fxLayoutAlign="start center"
					>
						{{row[eachColumn.key] | date:'MM/dd/YYYY'}}
					</div>

				</td>
			</ng-container>

			<!-- IMAGE -->
			<ng-container
				*ngIf="eachColumn.type === 'image'"
				[matColumnDef]="eachColumn.key"
			>
				<th
					*matHeaderCellDef
					[id]="'th_'+eachColumn.key"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="string-heading"
					mat-header-cell
					mat-sort-header
				>
					<div
						class="parent-cell ja-heading"
						fxLayoutAlign="start center"
					>
						{{eachColumn.displayName}}
					</div>
				</th>


				<td
					*matCellDef="let row"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="string-cell "
					mat-cell
				>
					<div
						class="parent-cell"
						fxLayoutAlign="start center"
					>
						<img
							alt=""
							class="image"
							src="{{ (row[eachColumn.key] ? ((eachColumn.cdnUrl? eachColumn.cdnUrl:'') + row[eachColumn.key]) : 'assets/images/png/teplo-leaf.png') }}"
						/>
					</div>
				</td>
			</ng-container>

			<!-- COLORED CELL -->
			<ng-container
				*ngIf="eachColumn.type === 'colored-cell'"
				[matColumnDef]="eachColumn.key"
			>
				<th
					*matHeaderCellDef
					[id]="'th_'+eachColumn.key"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="colored-cell-heading"
					mat-header-cell
					mat-sort-header
				>
					<div
						class="parent-cell ja-heading"
						fxLayoutAlign="start center"
					>
						{{eachColumn.displayName}}
					</div>
				</th>

				<td
					*matCellDef="let row"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="colored-cell-cell "
					mat-cell
				>
					<div
						class="colored-cell-parent parent-cell"
						fxLayoutAlign="start center"
					>
						<div
							[ngStyle]="{
                'color': eachColumn.colorPalette[row[eachColumn.colorPaletteKey]]?.color,
                'background-color': eachColumn.colorPalette[row[eachColumn.colorPaletteKey]]?.backgroundColor
              }"
							class="colored-cell-child"
							fxLayoutAlign="center center"
						>
							{{row[eachColumn.key]}}
						</div>
					</div>
				</td>
			</ng-container>

			<!-- INDICATOR -->
			<ng-container
				*ngIf="eachColumn.type === 'indicator'"
				[matColumnDef]="eachColumn.key"
			>
				<th
					*matHeaderCellDef
					[id]="'th_'+eachColumn.key"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="indicator-heading "
					mat-header-cell
				>
				</th>

				<td
					*matCellDef="let row"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="indicator-cell"
					mat-cell
				>
					<div
						class="indicator-cell-parent parent-cell"
						fxLayoutAlign="start center"
					>
						<div
							[ngStyle]="{
                'background-color': eachColumn.colorPalette[row[eachColumn.key]],
                'height': '1.5rem',
                'width': '1.5rem',
                'border-radius': '50%'
              }"
							class="indicator-cell-child"
						>
						</div>
					</div>
				</td>
			</ng-container>

			<!-- ACTION ITEM -->
			<ng-container
				*ngIf="eachColumn.type === 'action-item'"
				[matColumnDef]="eachColumn.key"
			>
				<th
					*matHeaderCellDef
					[id]="'th_'+eachColumn.key"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="indicator-heading "
					mat-header-cell
				>
				</th>

				<td
					*matCellDef="let row"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="action-item-cell"
					mat-cell
				>

					<div
						[ngStyle]="{
						'background-color': eachColumn.colorPalette[row[eachColumn.colorPaletteKey]]?.backgroundColor,
            'height': eachColumn.style.height,
            'width': eachColumn.style.width,
            'border-radius': eachColumn.style.borderRadius,
            'cursor': eachColumn.style.cursor
						}"
						class="parent-cell"
						fxLayoutAlign="center center"
					>
						<app-icon
							(click)="eachColumn.isEnabled(row) && eachColumn.handleClick(row)"
							[class.enabled]="eachColumn.isEnabled(row)"
							[icon]="eachColumn.icon"
							class="action-item-icon"
						></app-icon>
					</div>
				</td>
			</ng-container>

			<!-- ACTION MENU -->
			<ng-container
				*ngIf="eachColumn.type === 'action-menu'"
				[matColumnDef]="eachColumn.key"
			>
				<th
					*matHeaderCellDef
					[id]="'th_'+eachColumn.key"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="indicator-heading "
					mat-header-cell
				>
					<div
						class="parent-cell ja-heading"
						fxLayoutAlign="start center"
					>
						{{eachColumn.displayName}}
					</div>
				</th>

				<td
					*matCellDef="let row"
					[ngStyle]="{
            width: eachColumn.width
          }"
					class="action-menu-cell"
					mat-cell
				>
					<div
						class="parent-cell"
						fxLayoutAlign="center center"
					>
						<button
							[matMenuTriggerFor]="menu"
							mat-icon-button
						>
							<mat-icon>more_horiz</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<ng-container
								*ngFor="let childMenu of eachColumn.children"
							>
								<button
									(click)="childMenu.handleClick(row)" [class]="eachColumn.childrenClassName"
									mat-menu-item
								>
									<mat-icon>{{childMenu.icon}}</mat-icon>
									<span class=" ">{{childMenu.displayName}}</span>
								</button>
							</ng-container>
						</mat-menu>
					</div>
				</td>
			</ng-container>
		</div>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
	</table>
	<div
		*ngIf="showNoDataMessage"
		class="no-data "
		fxLayoutAlign="center center"
	>{{noDataMessage}}</div>
	<div
		class="pagination-section"
		fxLayout="row"
		fxLayoutAlign="space-between center"
	>
		<div class="total-count">
			<span class="ja-heading">{{translations[language].totalRecords}}:</span>
			<span class="ff-lang-ja-text-en">{{totalRecordCount || 0}}</span>
		</div>
		<mat-paginator
			(page)="getNext($event)"
			[length]="totalRecordCount"
			[pageIndex]="pageNumber - 1"
			[pageSizeOptions]="[10, 20, 50, 100]"
			[selectConfig]="{}"
			[showFirstLastButtons]="true"
			class="table-paginator"
		>
		</mat-paginator>
	</div>

</div>
