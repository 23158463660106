/**
 * External Modules
 */
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {EffectsModule} from '@ngrx/effects';

/**
 * CLI Provided Components
 */
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

/**
 * Created Modules
 */
import {ApiInterceptorService} from './modules/shared/services/api-interceptor/api-interceptor.service';
import {SharedModule} from './modules/shared/shared.module';

export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['shared', 'orderListPage', 'companyListPage', 'companyDetailsPage'],
    rehydrate: true,
    storage: sessionStorage
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [sessionStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    StoreModule.forRoot({}, {metaReducers}),
    EffectsModule.forRoot([]),
  ],
  exports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    SharedModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
