import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from '../../../../../../services/common/common.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../../state/app.state';
import * as SharedActions from '../../../../../../core/shared.actions';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.css']
})
export class LanguageDropdownComponent implements OnInit {
  @Input() languages;
  @Input() selectedLanguage;
  icon = 'caret-down';
  
  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
  ) {
  }
  
  ngOnInit(): void {
  }
  
  selectLang(event) {
    this.commonService.setLanguage(event.value);
    this.store.dispatch(SharedActions.FetchLanguageFilterListRequest({}))
  }
}
