<div
	class="wrapper"
	fxLayout="row"
	fxLayoutAlign="space-between"
>
	<div
		class="teplo-logo-container"
		fxLayoutAlign="center center"
	>
		<img
			(click)="goToLandingPage()"
			alt="Teplo-Logo"
			src="assets/images/png/teploLogo.png">
	</div>
	<div
		class="dropdown-container"
		fxLayout="row"
		fxLayoutAlign="end center"
	>
		<app-language-dropdown
			[languages]="languages"
			[selectedLanguage]="selectedLanguage"
			class="language-dropdown">
		</app-language-dropdown>

		<app-profile-menu></app-profile-menu>

	</div>
</div>
