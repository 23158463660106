import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LoginPageService} from '../../services/login-page.service';
import {CommonService} from '../../../../services/common/common.service';
import {v4 as uuidv4} from 'uuid';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../state/app.state';
import * as SharedSelectors from '../../../../core/shared.selectors';
import {roles} from '../../../../constants/base.constants.js';
import {Translations} from '../../../../../translations/shared-module/pages/login-form.translation';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  languageSubscription: Subscription;
  languageFilterListSubscription: Subscription;
  language: number;
  translations = {}
  errorMessageMap = {};
  errorMessages: any = {};
  clientDevice = {};
  uuid: string;
  os: string;
  model: string;
  version: string;
  appVersion = '0.0.0';
  languages = [];

  focus = {
    email: false,
    password: false
  }

  constructor(private loginServices: LoginPageService,
              private fb: FormBuilder,
              private commonService: CommonService,
              private router: Router,
              private store: Store<fromRoot.State>) {
    this.translations = Translations;
  }

  ngOnInit() {
    this.store.pipe(select(SharedSelectors.getProfile)).subscribe((data: any) => {
      if (this.commonService.isAuthenticated()) {
        if (data.role === roles.admin) {
          this.router.navigate(['/admin']);
        } else if (data.role === roles.inventoryPartner) {
          this.router.navigate(['/inventory-partner']);
        }
      }
    }).unsubscribe();

    this.languageFilterListSubscription = this.store.pipe(select(SharedSelectors.getLanguageFilterList)).subscribe(data => {
      this.languages = data;
    })

    this.uuid = uuidv4();
    this.os = this.commonService.OSName();
    this.model = this.commonService.browserDetails().browserName;
    this.version = this.commonService.browserDetails().browserVersion;
    this.clientDevice = {
      uuid: this.uuid,
      os: this.os,
      version: this.version,
      model: this.model,
      appVersion: this.appVersion,
    };

    this.form = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(this.commonService.emailRegex)]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
      ]),
    });

    this.languageSubscription = this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(selectedLanguage => {
      this.language = selectedLanguage;
      this.errorMessageMap = this.translations[this.language]?.errorMessageMap;
    });
  }

  login(): void {
    if (this.form.invalid) {
      this.checkForErrors();
      this.commonService.setFocus(this.form, this.errorMessages, this.focus);
      return;
    }
    const {email, password} = this.form.getRawValue();
    const payload = {
      email,
      password,
      clientDevice: this.clientDevice
    };
    this.loginServices.login.next(payload);
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
    this.languageFilterListSubscription?.unsubscribe();
  }
}
