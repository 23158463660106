import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import * as SharedActions from '../../../core/shared.actions';
import {SignUpPageService} from '../services/sign-up-page.service';

@Component({
  selector: 'app-sign-up-page-container',
  templateUrl: './sign-up-page-container.component.html',
  styleUrls: ['./sign-up-page-container.component.css']
})
export class SignUpPageContainerComponent implements OnInit, OnDestroy {
  signupSubscription: Subscription;

  constructor(private store: Store<fromRoot.State>,
              private signUpPageServices: SignUpPageService) {
    this.signupSubscription = this.signUpPageServices.signup.subscribe((payload) => {
      this.store.dispatch(SharedActions.SignUpRequest({payload}));
    });
    this.store.dispatch(SharedActions.FetchLanguageFilterListRequest({}))
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.signupSubscription?.unsubscribe();
  }
}
