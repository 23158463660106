<div
  class="wrapper"
  fxLayoutAlign="center center"
>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <app-icon
      class="icon"
      icon="sad-face"
    ></app-icon>
    <div class="error-code ">
      404
    </div>
    <div class="heading ">
      {{translations[language]?.pageNotFound}}
    </div>
  </div>
</div>
