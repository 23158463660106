/* NgRx */
import {createAction, props} from '@ngrx/store';


/******** CLEAR STATE ***********/
const CLEAR_STATE = '[shared] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** SET LANGUAGE ***********/
const SET_LANGUAGE = '[shared] set language';
export const SetLanguage = createAction(SET_LANGUAGE, props<any>());

/******** LOADING ***********/
const START_LOADING = '[shared] start loading';
export const StartLoading = createAction(START_LOADING);

const STOP_LOADING = '[shared] stop loading';
export const StopLoading = createAction(STOP_LOADING);

const CLEAR_LOADING = '[shared] clear loading';
export const ClearLoading = createAction(CLEAR_LOADING);

/******** LOGIN ***********/
const LOGIN_REQUEST = '[shared] login requested';
export const LoginRequest = createAction(LOGIN_REQUEST, props<any>());

const LOGIN_SUCCESS = '[shared] login successful';
export const LoginSuccess = createAction(LOGIN_SUCCESS, props<any>());

const LOGIN_FAILURE = '[shared] login failure';
export const LoginFailure = createAction(LOGIN_FAILURE, props<any>());

/******** LOGOUT ***********/
const LOGOUT_REQUEST = '[shared] logout requested';
export const LogoutRequest = createAction(LOGOUT_REQUEST, props<any>());

const LOGOUT_SUCCESS = '[shared] logout successful';
export const LogoutSuccess = createAction(LOGOUT_SUCCESS, props<any>());

const LOGOUT_FAILURE = '[shared] logout failure';
export const LogoutFailure = createAction(LOGOUT_FAILURE, props<any>());

/********* SEND INVITATION ***********/
const SEND_INVITATION_REQUEST = '[shared] send invitation requested';
export const SendInvitationRequest = createAction(SEND_INVITATION_REQUEST, props<any>());

const SEND_INVITATION_SUCCESS = '[shared] send invitation successful';
export const SendInvitationSuccess = createAction(SEND_INVITATION_SUCCESS, props<any>());

const SEND_INVITATION_FAILURE = '[shared] send invitation failure';
export const SendInvitationFailure = createAction(SEND_INVITATION_FAILURE, props<any>());

/*********  FETCH USER ROLES ***********/
const FETCH_USER_ROLES_FILTER_LIST_REQUEST = '[shared] fetch user roles filter list requested';
export const FetchUserRolesFilterListRequest = createAction(FETCH_USER_ROLES_FILTER_LIST_REQUEST, props<any>());

const FETCH_USER_ROLES_FILTER_LIST_SUCCESS = '[shared] fetch user roles filter list successful';
export const FetchUserRolesFilterListSuccess = createAction(FETCH_USER_ROLES_FILTER_LIST_SUCCESS, props<any>());

const FETCH_USER_ROLES_FILTER_LIST_FAILURE = '[shared] fetch user roles filter list failure';
export const FetchUserRolesFilterListFailure = createAction(FETCH_USER_ROLES_FILTER_LIST_FAILURE, props<any>());

/*********  FETCH COMPANY NAME ***********/
const FETCH_COMPANY_FILTER_LIST_REQUEST = '[shared] fetch company filter list requested';
export const FetchCompanyFilterListRequest = createAction(FETCH_COMPANY_FILTER_LIST_REQUEST, props<any>());

const FETCH_COMPANY_FILTER_LIST_SUCCESS = '[shared] fetch company filter list successful';
export const FetchCompanyFilterListSuccess = createAction(FETCH_COMPANY_FILTER_LIST_SUCCESS, props<any>());

const FETCH_COMPANY_FILTER_LIST_FAILURE = '[shared] fetch company filter list failure';
export const FetchCompanyFilterListFailure = createAction(FETCH_COMPANY_FILTER_LIST_FAILURE, props<any>());


/******** SIGNUP ***********/
const SIGNUP_REQUEST = '[shared] signup requested';
export const SignUpRequest = createAction(SIGNUP_REQUEST, props<any>());

const SIGNUP_SUCCESS = '[shared] signup successful';
export const SignUpSuccess = createAction(SIGNUP_SUCCESS, props<any>());

const SIGNUP_FAILURE = '[shared] signup failure';
export const SignUpFailure = createAction(SIGNUP_FAILURE, props<any>());

/******** FETCH COUNTRIES LIST ***********/
const FETCH_COUNTRIES_LIST_REQUEST = '[shared] fetch countries list requested';
export const FetchCountriesListRequest = createAction(FETCH_COUNTRIES_LIST_REQUEST, props<any>());

const FETCH_COUNTRIES_LIST_SUCCESS = '[shared] fetch countries list successful';
export const FetchCountriesListSuccess = createAction(FETCH_COUNTRIES_LIST_SUCCESS, props<any>());

const FETCH_COUNTRIES_LIST_FAILURE = '[shared] fetch countries list failure';
export const FetchCountriesListFailure = createAction(FETCH_COUNTRIES_LIST_FAILURE, props<any>());

/******** FETCH ORDER STATUS LIST ***********/
const FETCH_ORDER_STATUS_LIST_REQUEST = '[shared] fetch order status list requested';
export const FetchOrderStatusListRequest = createAction(FETCH_ORDER_STATUS_LIST_REQUEST, props<any>());

const FETCH_ORDER_STATUS_LIST_SUCCESS = '[shared] fetch order status list successful';
export const FetchOrderStatusListSuccess = createAction(FETCH_ORDER_STATUS_LIST_SUCCESS, props<any>());

const FETCH_ORDER_STATUS_LIST_FAILURE = '[shared] fetch order status list failure';
export const FetchOrderStatusListFailure = createAction(FETCH_ORDER_STATUS_LIST_FAILURE, props<any>());

/*********  FETCH PROFILE ***********/
const FETCH_PROFILE_REQUEST = '[shared] fetch profile requested';
export const FetchProfileRequest = createAction(FETCH_PROFILE_REQUEST, props<any>());

const FETCH_PROFILE_SUCCESS = '[shared] fetch profile successful';
export const FetchProfileSuccess = createAction(FETCH_PROFILE_SUCCESS, props<any>());

const FETCH_PROFILE_FAILURE = '[shared] fetch profile failure';
export const FetchProfileFailure = createAction(FETCH_PROFILE_FAILURE, props<any>());

/******** FETCH LANGUAGE FILTER LIST ***********/
const FETCH_LANGUAGE_FILTER_LIST_REQUEST = '[shared] fetch language filter list requested';
export const FetchLanguageFilterListRequest = createAction(FETCH_LANGUAGE_FILTER_LIST_REQUEST, props<any>());

const FETCH_LANGUAGE_FILTER_LIST_SUCCESS = '[shared] fetch language filter list successful';
export const FetchLanguageFilterListSuccess = createAction(FETCH_LANGUAGE_FILTER_LIST_SUCCESS, props<any>());

const FETCH_LANGUAGE_FILTER_LIST_FAILURE = '[shared] fetch language filter list failure';
export const FetchLanguageFilterListFailure = createAction(FETCH_LANGUAGE_FILTER_LIST_FAILURE, props<any>());

/******** SET PREVIOUS URL ***********/
const SET_PREVIOUS_URL = '[shared] set previous url';
export const SetPreviousUrl = createAction(SET_PREVIOUS_URL, props<any>());

/******** SET COUNTRY CODE ***********/
const SET_COUNTRY_CODE = '[shared] set country code';
export const SetCountryCode = createAction(SET_COUNTRY_CODE, props<any>());
