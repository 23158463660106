import * as SharedActions from './shared.actions';
import {createReducer, on} from '@ngrx/store';

export interface SharedState {
  loading: number[];
  language: number;
  countryCode: string,
  profile: {
    fullName: string,
    age: number,
    gender: number,
    email: string,
    image: string,
    role: number,
    roleName: string,
    unitSystem: number,
  },
  userRolesFilterList: any;
  companyFilterList: any;
  countriesList: any
  phoneCodesList: any
  orderStatusList: any
  languageFilterList: any
  previousUrl: string
}

const initialState: SharedState = {
  loading: [],
  language: 2,
  countryCode: 'JP',
  profile: {
    fullName: null,
    age: null,
    gender: null,
    email: null,
    image: null,
    role: null,
    roleName: null,
    unitSystem: null,
  },
  userRolesFilterList: [],
  companyFilterList: [],
  countriesList: [],
  phoneCodesList: [],
  orderStatusList: [],
  languageFilterList: [{
    key: 2,
    value: '日本語'
  }],
  previousUrl: null,
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(SharedActions.ClearState, (state) => initialState),

  // SET LANGUAGE
  on(SharedActions.SetLanguage, (state, props) => ({
    ...state,
    language: props.language,
  })),

  // START LOADING
  on(SharedActions.StartLoading, (state) => ({
    ...state,
    loading: [...state.loading, 1],
  })),

  // STOP LOADING
  on(SharedActions.StopLoading, (state) => ({
    ...state,
    loading: [...state.loading].slice(1),
  })),

  // CLEAR LOADING
  on(SharedActions.ClearLoading, (state) => ({
    ...state,
    loading: [],
  })),

  // LOGIN
  on(SharedActions.LoginRequest, (state) => state),
  on(SharedActions.LoginSuccess, (state, props) => ({
    ...state,
    profile: props.userProfile,
  })),
  on(SharedActions.LoginFailure, (state) => state),

  // LOGOUT
  on(SharedActions.LogoutRequest, (state) => state),
  on(SharedActions.LogoutSuccess, (state) => initialState),
  on(SharedActions.LogoutFailure, (state) => state),

  // SEND iNVITATION
  on(SharedActions.SendInvitationRequest, (state) => state),
  on(SharedActions.SendInvitationSuccess, (state, props) => ({
    ...state,
    // invite : props.invite,
  })),
  on(SharedActions.SendInvitationFailure, (state) => state),

  // FETCH USER ROLES
  on(SharedActions.FetchUserRolesFilterListRequest, (state) => state),
  on(SharedActions.FetchUserRolesFilterListSuccess, (state, props) => ({
    ...state,
    userRolesFilterList: props.userRolesFilterList,
  })),
  on(SharedActions.FetchUserRolesFilterListFailure, (state) => state),

  // FETCH COMPANY NAME
  on(SharedActions.FetchCompanyFilterListRequest, (state) => state),
  on(SharedActions.FetchCompanyFilterListSuccess, (state, props) => ({
    ...state,
    companyFilterList: props.companyFilterList,
  })),
  on(SharedActions.FetchCompanyFilterListFailure, (state) => state),

  // SIGNUP
  on(SharedActions.SignUpRequest, (state) => state),
  on(SharedActions.SignUpSuccess, (state, props) => ({
    ...state,
    profile: props.userProfile,
  })),
  on(SharedActions.SignUpFailure, (state) => state),

  // FETCH COUNTRIES LIST
  on(SharedActions.FetchCountriesListRequest, (state) => state),
  on(SharedActions.FetchCountriesListSuccess, (state, props) => ({
    ...state,
    countriesList: props.countriesList,
    phoneCodesList: props.phoneCodesList,
  })),
  on(SharedActions.FetchCountriesListFailure, (state) => state),

  // FETCH ORDER STATUS LIST
  on(SharedActions.FetchOrderStatusListRequest, (state) => state),
  on(SharedActions.FetchOrderStatusListSuccess, (state, props) => ({
    ...state,
    orderStatusList: props.orderStatusList,
  })),
  on(SharedActions.FetchOrderStatusListFailure, (state) => state),

  // FETCH PROFILE
  on(SharedActions.FetchProfileRequest, (state) => state),
  on(SharedActions.FetchProfileSuccess, (state, props) => ({
    ...state,
    profile: props.userProfile,
  })),
  on(SharedActions.FetchProfileFailure, (state) => state),

  // FETCH LANGUAGE FILTER LIST
  on(SharedActions.FetchLanguageFilterListRequest, (state) => state),
  on(SharedActions.FetchLanguageFilterListSuccess, (state, props) => ({
    ...state,
    languageFilterList: props.languageFilterList,
  })),
  on(SharedActions.FetchLanguageFilterListFailure, (state) => state),

  // SET PREVIOUS URL
  on(SharedActions.SetPreviousUrl, (state, props) => ({
    ...state,
    previousUrl: props.previousUrl,
  })),

  // SET COUNTRY CODE
  on(SharedActions.SetCountryCode, (state, props) => ({
    ...state,
    countryCode: props.countryCode,
  })),
);
