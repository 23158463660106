interface TableTranslation {
  totalRecords: string;
}

const en: TableTranslation = {
  totalRecords: 'Total Records',
}

const ja: TableTranslation = {
  totalRecords: '合計',
}

export const Translations = {
  1: en,
  2: ja
}
