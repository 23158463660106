import {Injectable} from '@angular/core';
import {CommonService} from '../services/common/common.service';


import {Observable} from 'rxjs';
import {v1URL} from '../constants/urls.constants';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private commonServices: CommonService) {
  }

  login(payload): Observable<any> {
    const urlConfig = v1URL.user.login
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  logout(payload): Observable<any> {
    const urlConfig = v1URL.user.logout
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  sendInvitation(payload): Observable<any> {
    const urlConfig = v1URL.dashboard.admin.invite.invite
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  signup(payload): Observable<any> {
    const urlConfig = v1URL.dashboard.common.invite.accept
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload);
  }

  fetchUserRolesList(): Observable<any> {
    const urlConfig = v1URL.filter.userRoles.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  fetchCompanyList(): Observable<any> {
    const urlConfig = v1URL.filter.company.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  fetchCountries(): Observable<any> {
    const urlConfig = v1URL.filter.countries.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  fetchOrderStatusList(): Observable<any> {
    const urlConfig = v1URL.filter.orderStatus.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  fetchProfile(): Observable<any> {
    const urlConfig = v1URL.user.profile.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  fetchLanguageList(): Observable<any> {
    const urlConfig = v1URL.filter.language.fetch
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

}
