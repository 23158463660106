<div
  class="external-wrapper-container"
  fxLayout.gt-lg="row"
  fxLayout.lg="row"
  fxLayout.md="row"
  fxLayout.sm="column"
  fxLayout.xs="column"
  ngClass.gt-lg="xl"
  ngClass.lg="lg"
  ngClass.md="md"
  ngClass.sm="sm"
  ngClass.xs="xs"
>
  <div
    class="left"
    fxFlex.gt-lg="50"
    fxFlex.lg="50"
    fxFlex.md="50"
    fxFlex.sm="100"
    fxFlex.xs="100"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      class="teplo-outer-container"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutAlign.xs="start center"
    >
      <div class="teplo-inner-container">
        <div class="teplo-logo-container" fxLayoutAlign="center center">
          <img alt="teplo_logo" src="../../../../../../assets/images/png/teploLogo.png">
        </div>
        <div
          class="teplo-image-container"
          fxLayoutAlign="center center"
          fxShow.xs="false"
        >
          <img alt="teplo_tea_pot" src="../../../../../../assets/videos/webp/teplo_tea_pot_360.webp">
        </div>
      </div>
    </div>
    <div class="background-image-outer-panel">
      <div class="background-image-inner-panel">
        <div class="diffuser-top-1" fxLayoutAlign="center center">
          <img alt="" src="../../../../../../assets/images/png/blackDiffuserTop.png">
        </div>
        <div class="diffuser-top-2" fxLayoutAlign="center center">
          <img alt="" src="../../../../../../assets/images/png/yellowDiffuserTop.png">
        </div>
        <div class="diffuser-bottom-1" fxLayoutAlign="center center">
          <img alt="" src="../../../../../../assets/images/png/blackDiffuserBottom.png">
        </div>
        <div
          class="diffuser-bottom-2"
          fxLayoutAlign="center center"
        >
          <img alt="" src="../../../../../../assets/images/png/yellowDiffuserBottom.png">
        </div>
      </div>
    </div>
  </div>
  <div
    class="right"
    fxFlex.gt-lg="50"
    fxFlex.lg="50"
    fxFlex.md="50"
    fxFlex.sm="100"
    fxFlex.xs="100"
    fxLayout="column"
    fxLayoutAlign="center stretch"
  >
    <div
      class="login-form-container"
      fxLayout="column"
      fxLayoutAlign="center stretch"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
