<div
  [ngClass.gt-lg]="'xl'"
  [ngClass.lg]="'lg'"
  [ngClass.md]="'md'"
  [ngClass.sm]="'sm'"
  [ngClass.xs]="'xs'"
  class="container"
  fxLayoutAlign="center center"
>
  <div class="diffuser-panel">
    <img
      alt="black-diffuser-top"
      class="diffuser-top"
      src="../../../../../../assets/images/png/diffuser_top.png"
    >
    <img
      alt="black-diffuser-bottom"
      class="diffuser-bottom"
      src="../../../../../../assets/images/png/diffuser_bottom.png"
    >
  </div>

  <div class="under-maintenance-box"
       fxLayout="column"
       fxLayout.sm="column"
       fxLayout.xs="column"
       fxLayoutAlign="center center"
       fxLayoutGap.md="10"
       fxLayoutGap.sm="0"
       fxLayoutGap.xs="5">
    <img
      alt="under-maintenance-img"
      class="under-maintenance-img"
      src="../../../../../../assets/images/png/under-maintenance.png"
    >
    <img
      alt="teplo-logo"
      class="teplo-logo"
      src="../../../../../../assets/images/png/teploLogo.png"
    >
    <div
      class="error-message"
    >
      {{translations[language].errorMessage}}
    </div>
    <div
      class="message"
    >
      {{translations[language].message}}
    </div>
  </div>


</div>
