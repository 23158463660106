import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import {Router} from '@angular/router';
import {Translations} from '../../../../translations/shared-module/components/base/page-under-maintenance.translation';
import * as SharedSelectors from '../../../core/shared.selectors';
import {CommonService} from '../../../services/common/common.service';

@Component({
  selector: 'app-page-under-maintenance',
  templateUrl: './page-under-maintenance.component.html',
  styleUrls: ['./page-under-maintenance.component.css']
})
export class PageUnderMaintenanceComponent implements OnInit {

  translations: any;
  languageSubscription: Subscription;
  languageFilterListSubscription: Subscription;

  language: number;
  languages = [];

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private commonService: CommonService,
  ) {
    this.translations = Translations;
  }

  ngOnInit(): void {
    this.languageSubscription = this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(selectedLanguage => {
      this.language = selectedLanguage;
    });

    this.languageFilterListSubscription = this.store.pipe(select(SharedSelectors.getLanguageFilterList)).subscribe(data => {
      this.languages = data;
    })
  }
}
