import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './modules/shared/services/auth-guard/auth-guard.service';
import {AdminGuardService} from './modules/shared/services/admin-guard/admin-guard.service';
import {
  InternalPageNotFoundComponent
} from './modules/shared/components/base/internal-page-not-found/internal-page-not-found.component';
import {environment} from '../environments/environment';
import {
  AuthenticatedWrapperComponent
} from './modules/shared/components/wrappers/authenticated-wrapper/authenticated-wrapper.component';
import {
  ExternalWrapperComponent
} from './modules/shared/components/wrappers/external-wrapper/external-wrapper.component';
import {
  LoginPageContainerComponent
} from './modules/shared/pages/login-page/login-page-container/login-page-container.component';
import {
  SignUpPageContainerComponent
} from './modules/shared/pages/sign-up-page/sign-up-page-container/sign-up-page-container.component';
import {
  InventoryPartnerGuardService
} from './modules/shared/services/inventory-partner-guard/inventory-partner-guard.service';
import {
  ExternalPageNotFoundComponent
} from './modules/shared/components/base/external-page-not-found/external-page-not-found.component';
import {
  PageUnderMaintenanceComponent
} from './modules/shared/components/base/page-under-maintainance/page-under-maintenance.component';

const samples = [];
if (!environment.production) {
  samples.push({
    path: 'samples',
    canActivate: [AuthGuardService],
    loadChildren: () => {
      return import('./modules/samples/samples.module').then(m => m.SamplesModule)
    }
  });
}

const routes: Routes = [
  {
    path: '',
    component: ExternalWrapperComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginPageContainerComponent
      },
      {
        path: 'signup',
        component: SignUpPageContainerComponent
      }
    ]
  },
  {
    path: 'maintenance',
    component: PageUnderMaintenanceComponent
  },
  {
    path: 'admin',
    component: AuthenticatedWrapperComponent,
    canActivate: [AuthGuardService, AdminGuardService],
    children: [
      {
        path: '',
        redirectTo: 'orders',
        pathMatch: 'full'
      },
      {
        path: 'orders',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/admin/order-list-page/order-list-page.module')
            .then(m => m.OrderListPageModule)
      },
      {
        path: 'create-order',
        loadChildren: () =>
          import('./modules/admin/create-order-page/create-order-page.module')
            .then(m => m.CreateOrderPageModule)
      },
      {
        path: 'orders/:id',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/admin/order-details-page/order-details-page.module')
            .then(m => m.OrderDetailsPageModule)
      },
      {
        path: 'companies',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/admin/company-list-page/company-list-page.module')
            .then(m => m.CompanyListPageModule)
      },
      {
        path: 'companies/details/:id',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/admin/company-details-page/company-details-page.module')
            .then(m => m.CompanyDetailsPageModule)
      },
      {
        path: 'companies/add',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/admin/company-details-page/company-details-page.module')
            .then(m => m.CompanyDetailsPageModule)
      },
      {
        path: '**',
        component: InternalPageNotFoundComponent
      },
    ]
  },
  {
    path: 'inventory-partner',
    component: AuthenticatedWrapperComponent,
    canActivate: [AuthGuardService, InventoryPartnerGuardService],
    children: [
      {
        path: '',
        redirectTo: 'orders',
        pathMatch: 'full'
      },
      {
        path: 'orders',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/inventory-partner/order-list-page/order-list-page.module')
            .then(m => m.OrderListPageModule)
      },
      {
        path: 'orders/:id',
        pathMatch: 'full',
        loadChildren: () =>
          import('./modules/inventory-partner/order-details-page/order-details-page.module')
            .then(m => m.OrderDetailsPageModule)
      },
      {
        path: '**',
        component: InternalPageNotFoundComponent
      },
    ]
  },
  ...samples,
  {
    path: '**', component: ExternalPageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
