interface ExternalPageNotFoundTranslation {
  errorMessage: string;
  message: string;
  loginButton: string;
}

const en: ExternalPageNotFoundTranslation = {
  errorMessage: 'Ooops! Page not found',
  message: 'to continue, Please Login...',
  loginButton: 'Login'
}

const ja: ExternalPageNotFoundTranslation = {
  errorMessage: 'ページが見つかりません',
  message: '続行するためにログインが必要です',
  loginButton: 'ログイン'
}

export const Translations = {
  1: en,
  2: ja
}
