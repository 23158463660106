<mat-icon
  [matBadge]="badgeValue"
  [ngStyle]="{
    'vertical-align': 'middle',
    'fill': color
  }"
  [svgIcon]="icon"
  class="icon"
  matBadgeColor="warn"
></mat-icon>
