import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-login-page-screen',
  templateUrl: './login-page-screen.component.html',
  styleUrls: ['./login-page-screen.component.css']
})
export class LoginPageScreenComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }

}
