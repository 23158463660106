interface SignUpFormTranslation {
  heading: string,
  nameLabel: string,
  emailLabel: string,
  namePlaceholder: string,
  emailPlaceholder: string,
  passwordLabel: string,
  passwordPlaceholder: string,
  confirmPasswordLabel: string,
  confirmPasswordPlaceholder: string,
  signUpButton: string,
  errorMessageMap: object,
  redirectToLogin: string,
  login: string;
}

const en: SignUpFormTranslation = {
  heading: 'Sign Up',
  nameLabel: 'Name',
  emailLabel: 'Email Address',
  emailPlaceholder: 'Email Address',
  namePlaceholder: 'Name',
  passwordLabel: 'Password',
  passwordPlaceholder: 'Password',
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordPlaceholder: 'Confirm Password',
  signUpButton: 'Sign Up',
  errorMessageMap: {
    name: {
      required: 'This field is required.',
      minlength: 'Name must contain at least 1 character',
      maxlength: 'Name must contain only 25 characters',
    },
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email.'
    },
    password: {
      required: 'This field is required.',
      minlength: 'Password must contain at least 8 characters',
      maxlength: 'Password must contain only 20 characters',
      lowerCaseCheckFailed: 'Password must contain at least 1 Lowercase character',
      upperCaseCheckFailed: 'Password must contain at least 1 Uppercase character',
      numberCheckFailed: 'Password must contain at least 1 numeric character',
      specialCharCheckFailed: 'Password must contain a Special character',
    },
    confirmPassword: {
      required: 'This field is required.',
      minlength: 'Password must contain at least 8 characters',
      maxlength: 'Password must contain only 20 characters',
      lowerCaseCheckFailed: 'Password must contain at least 1 Lowercase character',
      upperCaseCheckFailed: 'Password must contain at least 1 Uppercase character',
      numberCheckFailed: 'Password must contain at least 1 numeric character',
      specialCharCheckFailed: 'Password must contain a Special character',
      passwordMatchFailed: 'Confirm Password should be same as Password.'
    },
  },
  redirectToLogin: 'Already Registered? Click here to',
  login: 'Login'
}

const ja: SignUpFormTranslation = {
  heading: 'サインアップ',
  nameLabel: '名前',
  emailLabel: 'メールアドレス',
  emailPlaceholder: 'メールアドレス',
  namePlaceholder: '名前',
  passwordLabel: 'パスワード',
  passwordPlaceholder: 'パスワード',
  confirmPasswordLabel: 'パスワードを再入力',
  confirmPasswordPlaceholder: 'パスワードを再入力',
  signUpButton: 'サインアップ',
  errorMessageMap: {
    name: {
      required: '入力が必須な項目です',
      minlength: '名前は1文字以上で入力してください',
      maxlength: '名前は25文字以内で入力してください',
    },
    email: {
      required: '入力が必須な項目です',
      pattern: '無効なメールアドレス'
    },
    password: {
      required: '入力が必須な項目です',
      minlength: 'パスワードは半角で8文字以上で設定してください',
      maxlength: 'パスワードは半角で20文字以内で設定してください',
      lowerCaseCheckFailed: 'パスワードは1文字以上の半角小文字の英語を入れてください',
      upperCaseCheckFailed: 'パスワードは1文字以上の半角大文字の英語を入れてください',
      numberCheckFailed: 'パスワードは1文字以上の半角数字を入れてください',
      specialCharCheckFailed: 'パスワードは1文字以上の半角特殊文字(&や@等)を入れてください',
    },
    confirmPassword: {
      required: '入力が必須な項目です',
      minlength: 'パスワードは半角で8文字以上で設定してください',
      maxlength: 'パスワードは半角で20文字以内で設定してください',
      lowerCaseCheckFailed: 'パスワードは1文字以上の半角小文字の英語を入れてください',
      upperCaseCheckFailed: 'パスワードは1文字以上の半角大文字の英語を入れてください',
      numberCheckFailed: 'パスワードは1文字以上の半角数字を入れてください',
      specialCharCheckFailed: 'パスワードは1文字以上の半角特殊文字(&や@等)を入れてください',
      passwordMatchFailed: 'パスワードが一致しません'
    },
  },
  redirectToLogin: 'メンバー登録済の場合はこちらからログイン',
  login: 'ログイン'
}

export const Translations = {
  1: en,
  2: ja
}
