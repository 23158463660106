<div
  [class.selected]="isActive"
  [queryParams]="option.queryParams || {}"
  [routerLink]="option.link"
  class="each-item"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
>
  <app-icon
    [icon]="option.icon"
    class="icon">
  </app-icon>
  <div
    fxLayoutAlign="space-around center"
  >
    <div class="label ja-heading">
      {{option.label}}
    </div>
    <div
      *ngIf="badgeValue"
      [class.short]="badgeValue < 10"
      class="badge "
      fxLayoutAlign="center center"
    >
      <span *ngIf="badgeValue < 1000; else higherCount">{{badgeValue}}</span>
      <ng-template #higherCount>
        <span>999+</span>
      </ng-template>
    </div>
  </div>
</div>

