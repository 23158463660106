<mat-form-field
  [formGroup]="parentFormGroup"
  class="date-time-picker"
  floatLabel="{{parentFormGroup.controls[this.controlName].status === 'DISABLED' ? 'always' : showLabel ? 'auto' : 'never'}}"
>
  <mat-label>{{label}}</mat-label>
  <input
    #input
    [formControlName]="controlName"
    [matDatetimepicker]="dts"
    [required]="isRequired"
    autocomplete="false"
    matInput
  >
  <mat-datetimepicker-toggle [for]="dts" matSuffix></mat-datetimepicker-toggle>
  <mat-datetimepicker #dts openOnFocus="false" type="datetime"></mat-datetimepicker>
</mat-form-field>
