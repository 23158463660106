<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
>
  <mat-form-field
    [ngClass]="{
      'has-prefix': !!prefixIcon,
      'has-suffix': !!suffixIcon,
      'has-data': !!parentFormGroup.controls[controlName].value.length
    }"
    appearance="outline"
    class="form-field"
    hideRequiredMarker
  >
    <mat-chip-grid
      #chipGrid
      [formControl]="parentFormGroup.controls[controlName]"
    >
      <mat-chip-row
        (removed)="removeKeyword(eachChip)"
        *ngFor="let eachChip of parentFormGroup.controls[controlName].value"
        class="category-chip"
      >
        <div class="category-chip__text"> {{eachChip}} </div>
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        (matChipInputTokenEnd)="add($event)"
        [matChipInputAddOnBlur]="false"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [placeholder]="placeholder"
      />
    </mat-chip-grid>
    <mat-icon
      *ngIf="prefixIcon"
      class="form-field-icon prefix-icon"
      fxLayoutAlign="center center"
      matPrefix
    >
      {{prefixIcon}}
    </mat-icon>
    <mat-icon
      *ngIf="suffixIcon"
      class="form-field-icon suffix-icon"
      fxLayoutAlign="center center"
      matSuffix
    >
      {{suffixIcon}}
    </mat-icon>
  </mat-form-field>
  <div
    *ngIf="error"
    class="form-field-error"
    fxLayoutAlign="start end"
  >
    {{error}}
  </div>
</div>
