<div
	class="wrapper"
	fxLayout="row"
	fxLayoutAlign="center center"
	fxLayoutGap="1rem"
	ngClass.xs="xs"
>
	<app-icon
		class="language-icon"
		icon="language"
	></app-icon>
	<mat-form-field
		appearance="outline"
	>
		<mat-select
			(selectionChange)="selectLang($event)"
			[ngModel]="selectedLanguage"
			id="language_dropdown"
			panelClass="language-selector-options-list"
		>
			<mat-option
				*ngFor="let lang of languages"
				[id]="'language_'+lang.key"
				[value]="lang.key"
				class="each-language"
			>
				<span
					[ngClass]="
					lang.key === 1 && selectedLanguage === 1? 'lang-en' :
					 lang.key === 2 && selectedLanguage === 1?'lang-en':
					 lang.key === 1 && selectedLanguage === 2?'lang-en':
					 lang.key === 2 && selectedLanguage === 2?'ja-heading':''"
				>{{ lang.value }}</span>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
