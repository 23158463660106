/* tslint:disable */
import {EventEmitter, Inject, Injectable} from '@angular/core';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {Subject, throwError} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import * as SharedActions from '../../core/shared.actions';
import * as SharedSelectors from '../../core/shared.selectors';
import * as fromRoot from '../../../../state/app.state';
import {select, Store} from '@ngrx/store';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import {BasicDialogComponent} from '../../components/base/dialogbox/basic-dialog/basic-dialog.component';

import * as OrderListPageActions from '../../../common/order-list-page/core/order-list.actions';
import * as OrderDetailsPageActions from '../../../common/order-details-page/core/order-details.actions';
import {
  ConfirmationDialogComponent
} from '../../components/base/dialogbox/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  // checkForSpace = '^[a-zA-Z0-9]+(\\s[a-zA-Z0-9]+)?$';
  checkForSpace = '^[a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*$';
  emailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';
  passwordRegex = new RegExp('^(?=.*[A-Z])(?=.*[!@#$%^&*()\\\\/\\[\\]\\-_=+{}|?>.<,:;~`\'÷×₹€¥"])(?=.*[0-9])(?=.*[a-z]).{8,20}$')
  closeLoadingScreen = new EventEmitter();
  clearSendInviteForm = new Subject();
  isLoadingScreenOpen = new Subject<boolean>();

  triggerAppStateClear = new Subject();
  previousUrl: string;
  countryCode: string;
  // Used for copyToClipboard(value)
  private dom: Document;
  private language: number;
  private destroyed = new Subject<void>();
  private role: number;

  constructor(
    private store: Store<fromRoot.State>,
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    @Inject(DOCUMENT) dom: Document
  ) {

    // Used for copyToClipboard(value)
    this.dom = dom;

    this.store
      .pipe(select(SharedSelectors.getLanguage))
      .pipe(takeUntil(this.destroyed))
      .subscribe((selectedLanguage) => {
        this.language = selectedLanguage;
      });

    this.store.pipe(select(SharedSelectors.getPreviousUrl))
      .pipe(takeUntil(this.destroyed))
      .subscribe(data => {
        this.previousUrl = data;
      });

    this.store.pipe(select(SharedSelectors.getCountryCode))
      .pipe(takeUntil(this.destroyed))
      .subscribe(countryCode => {
        this.countryCode = countryCode;
      });

  }

  setToLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  getFromLocalStorage(key) {
    return localStorage.getItem(key);
  }

  setToSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }

  getFromSessionStorage(key) {
    return sessionStorage.getItem(key);
  }

  removeFromSessionStorage(key) {
    sessionStorage.removeItem(key);
  }

  setAuthenticationToken(token) {
    this.setToLocalStorage('token', token);
  }

  getAuthenticationToken() {
    return this.getFromLocalStorage('token');
  }

  removeAuthenticationToken() {
    return this.removeFromLocalStorage('token');
  }

  isAuthenticated() {
    return !!this.getAuthenticationToken();
  }

  logout() {
    this.store.dispatch(SharedActions.LogoutRequest({}));
  }

  clearAppState() {
    this.closeDialog();
    this.store.dispatch(SharedActions.ClearState());
    this.store.dispatch(OrderListPageActions.ClearState());
    this.store.dispatch(OrderDetailsPageActions.ClearState());
  }

  clearStorage() {
    this.removeAuthenticationToken();

    const appLocalStorageKeys = [
      'shared',
      'orderListPage'
    ];

    appLocalStorageKeys.forEach((key) => {
      this.removeFromLocalStorage(key);
    });
  }

  callAPI(type, url, payload?, options = {}) {
    let apiCall = this.http[type]<any>(url, payload, {
      observe: 'response',
      ...options,
    });

    if (type === 'delete') {
      apiCall = this.http[type]<any>(url, {
        observe: 'response',
        body: payload || {},
        ...options,
      });
    }

    return apiCall.pipe(
      map((response: HttpResponse<any>) => {
        if (response instanceof HttpResponse) {
          return response.body;
        }

        return response;
      }),
      catchError((error) => throwError(error))
    );
  }

  openInNewTab(url) {
    window.open(url, '_blank');
  }

  openInCurrentTab(url) {
    window.open(url, '_self');
  }

  startLoading() {
    this.store.dispatch(SharedActions.StartLoading());
  }

  stopLoading() {
    this.store.dispatch(SharedActions.StopLoading());
  }

  notification(message, type?, actionText?, notificationDuration?) {
    let duration = 2000;
    if (notificationDuration) {
      duration = notificationDuration;
    }
    this.snackBar.open(message, actionText, {
      duration,
      verticalPosition: 'bottom', // 'top' | 'bottom'
      horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
      panelClass: [type],
    });
  }

  openDialog(config = {}) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'dialog';
    this.dialog.open(BasicDialogComponent, {...dialogConfig, ...config});
  }

  openConfirmationDialog(config = {}) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'confirmation-dialog';
    this.dialog.open(ConfirmationDialogComponent, {...dialogConfig, disableClose: true, ...config});
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  checkFormValidation(form, errorMessageMap, currentField?) {
    const errorMessages = {};
    const formControls = form.controls;

    if (currentField) {
      const errors = form.controls[currentField].errors;
      const firstErrorType = errors ? Object.keys(errors)[0] : null;
      errorMessages[currentField] = errorMessageMap[currentField][firstErrorType];
      return errorMessages;
    }

    for (const eachControlName in formControls) {
      const isControlValid = form.controls[eachControlName].valid;
      if (!isControlValid) {
        const errors = form.controls[eachControlName].errors;
        const firstErrorType = errors ? Object.keys(errors)[0] : null;
        if (firstErrorType) {
          form.controls[eachControlName].markAsTouched();
          errorMessages[eachControlName] = errorMessageMap[eachControlName][firstErrorType];
        }
      }
    }
    return errorMessages;
  }

  validatePassword(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    let errors = {};

    const containsLowercase = new RegExp('[a-z]');
    if (!containsLowercase.test(password)) {
      errors = {
        ...errors,
        lowerCaseCheckFailed: true
      }
      return errors;
    }

    const containsUppercase = new RegExp('[A-Z]');
    if (!containsUppercase.test(password)) {
      errors = {
        ...errors,
        upperCaseCheckFailed: true
      }
      return errors;
    }

    const containsNumber = new RegExp('[0-9]');
    if (!containsNumber.test(password)) {
      errors = {
        ...errors,
        numberCheckFailed: true
      }
      return errors;
    }

    const containsSpecialChar = new RegExp('[!@#$%^&*()\\\\/\\[\\]\-_=+{}|?>.<,:;~`\'÷×₹€¥"]');
    if (!containsSpecialChar.test(password)) {
      errors = {
        ...errors,
        specialCharCheckFailed: true
      }
      return errors;
    }

    return null;
  }

  OSName() {
    let OSName;
    if (navigator.userAgent.indexOf('Win') !== -1) OSName = 'Windows';
    if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Macintosh';
    if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux';
    if (navigator.userAgent.indexOf('Android') !== -1) OSName = 'Android';
    if (navigator.userAgent.indexOf('like Mac') !== -1) OSName = 'iOS';
    return OSName;
  }

  browserDetails() {
    let browserAgent = navigator.userAgent;
    const objappVersion = navigator.appVersion;
    browserAgent = navigator.userAgent;
    let browserName = navigator.appName;
    let browserVersion = '' + parseFloat(navigator.appVersion);
    let browserMajorVersion = parseInt(navigator.appVersion, 10);
    let Offset, OffsetVersion, ix;

    // For Chrome
    if ((OffsetVersion = browserAgent.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome';
      browserVersion = browserAgent.substring(OffsetVersion + 7);
    }

    // For Microsoft internet explorer
    else if ((OffsetVersion = browserAgent.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer';
      browserVersion = browserAgent.substring(OffsetVersion + 5);
    }

    // For Firefox
    else if ((OffsetVersion = browserAgent.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox';
    }

    // For Safari
    else if ((OffsetVersion = browserAgent.indexOf('Safari')) !== -1) {
      browserName = 'Safari';
      browserVersion = browserAgent.substring(OffsetVersion + 7);
      if ((OffsetVersion = browserAgent.indexOf('Version')) !== -1)
        browserVersion = browserAgent.substring(OffsetVersion + 8);
    }

    // For other browser "name/version" is at the end of userAgent
    else if ((Offset = browserAgent.lastIndexOf(' ') + 1) <
      (OffsetVersion = browserAgent.lastIndexOf('/'))) {
      browserName = browserAgent.substring(Offset, OffsetVersion);
      browserVersion = browserAgent.substring(OffsetVersion + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    // Trimming the fullVersion string at
    // semicolon/space if present
    if ((ix = browserVersion.indexOf(';')) !== -1)
      browserVersion = browserVersion.substring(0, ix);
    if ((ix = browserVersion.indexOf(' ')) !== -1)
      browserVersion = browserVersion.substring(0, ix);


    browserMajorVersion = parseInt('' + browserVersion, 10);
    if (isNaN(browserMajorVersion)) {
      browserVersion = '' + parseFloat(navigator.appVersion);
      browserMajorVersion = parseInt(navigator.appVersion, 10);
    }
    return {browserName, browserVersion}
  }

  setLanguage(language: number, fromUrl = false) {
    this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(data => {
      if (data !== language) {
        this.store.dispatch(SharedActions.SetLanguage({language: language}));
      }

      if (fromUrl) {
        this.router.navigate([], {
          queryParams: {
            'language': null
          },
          queryParamsHandling: 'merge'
        })
      } else {
        this.reloadCurrentRoute();
      }
    }).unsubscribe();
  }

  getLanguage() {
    return this.language;
  }

  getCountryCode() {
    return this.countryCode;
  }

  getRole() {
    this.store.pipe(select(SharedSelectors.getProfile)).subscribe((data: any) => this.role = data.role).unsubscribe();
    return this.role;
  }

  getRoleNameForAPIPath(role) {
    const urlBase = this.router.url.split('/')[1];

    if (urlBase === 'admin') return 'admin';

    if (urlBase === 'inventory-partner') return 'inventoryPartner';
  }

  setFocus(form, errorMessages, focus) {
    const formFieldsList = Object.keys(form.controls);

    for (const eachFormField of formFieldsList) {
      if (errorMessages[eachFormField] !== undefined) {
        focus[eachFormField] = true;
        return;
      } else {
        focus[eachFormField] = false;
      }
    }
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
  }

  getFormDirtyRawValues(form: FormGroup) {
    const updatedValues: any = {};
    for (let controlsKey in form.controls) {
      const control = form.controls[controlsKey];
      if (control instanceof FormGroup && control.dirty) {
        // looking sub form group
        const dirtyValues = this.getFormDirtyRawValues(control);
        if (Object.keys(dirtyValues).length) {
          updatedValues[controlsKey] = dirtyValues;
        }
      } else if (control.dirty) {
        updatedValues[controlsKey] = control.getRawValue();
      }
    }
    return updatedValues;
  }
}
