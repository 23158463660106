import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as SharedSelectors from '../../../../core/shared.selectors';
import * as fromRoot from '../../../../../../state/app.state';
import {CommonService} from '../../../../services/common/common.service';
import {SignUpPageService} from '../../services/sign-up-page.service';
import {ActivatedRoute, Router} from '@angular/router';
import {v4 as uuidv4} from 'uuid';
import {Translations} from '../../../../../translations/shared-module/pages/sign-up-form.translation';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.css']
})


export class SignUpFormComponent implements OnInit, OnDestroy {

  email: string;
  companyName: string;
  enableForm = true;

  form: FormGroup;
  errorMessages: any = {};
  errorMessageMap = {};
  languageSubscription: Subscription;
  languageFilterListSubscription: Subscription;
  language: number;
  translations = {}
  clientDevice = {};
  uuid: string;
  os: string;
  model: string;
  version: string;
  appVersion = '0.0.0';
  languages = [];

  focus = {
    name: false,
    email: false,
    password: false,
    confirmPassword: false
  }

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private signupServices: SignUpPageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.translations = Translations;
  }

  ngOnInit(): void {

    this.languageFilterListSubscription = this.store.pipe(select(SharedSelectors.getLanguageFilterList)).subscribe(data => {
      this.languages = data;
    })

    this.route.queryParams.subscribe(params => {
      let {data} = params;
      try {
        data = JSON.parse(atob(params.data));
        this.email = data?.email;
        this.companyName = data?.companyName;
      } catch (e) {
        this.email = '';
        this.enableForm = false;
      }
    });

    this.uuid = uuidv4();
    this.os = this.commonService.OSName();
    this.model = this.commonService.browserDetails().browserName;
    this.version = this.commonService.browserDetails().browserVersion;
    this.clientDevice = {
      uuid: this.uuid,
      os: this.os,
      version: this.version,
      model: this.model,
      appVersion: this.appVersion,
    };

    this.form = this.fb.group({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(25),
      ]),
      email: new FormControl({
        value: this.email,
        disabled: true
      }, [Validators.required, Validators.pattern(this.commonService.emailRegex)]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        this.commonService.validatePassword
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
      ]),
    }, {validators: [this.validateConfirmPassword]});


    this.languageSubscription = this.store.pipe(select(SharedSelectors.getLanguage)).subscribe(selectedLanguage => {
      this.language = selectedLanguage;
      this.errorMessageMap = this.translations[this.language]?.errorMessageMap;
    });

  }

  signup(): void {
    if (this.form.invalid) {
      this.checkForErrors();
      this.commonService.setFocus(this.form, this.errorMessages, this.focus);
      return;
    }
    const {name, password, confirmPassword} = this.form.getRawValue();
    const payload = {
      fullName: name,
      password,
      confirmPassword,
      clientDevice: this.clientDevice
    };
    this.signupServices.signup.next(payload);
  }

  checkForErrors(currentField ?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  validateConfirmPassword(control: AbstractControl): ValidationErrors | null {
    if (control && control.get('password') && control.get('confirmPassword')) {
      const password = control.get('password').value;
      const confirmPassword = control.get('confirmPassword').value;

      if (password && confirmPassword && password !== confirmPassword) {
        control.get('confirmPassword').setErrors({
          ...(control.get('confirmPassword').errors ? control.get('confirmPassword').errors : {}),
          passwordMatchFailed: true
        })
      }
    }
    return null;
  }

  redirectToLogin() {
    this.router.navigate(['/login'])
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

}
