<div
  [ngClass.lg]="'lg'"
  [ngClass.md]="'md'"
  [ngClass.sm]="'sm'"
  [ngClass.xl]="'xl'"
  [ngClass.xs]="'xs'"
  class="login-form-component"
  fxLayoutAlign="center center"
>
  <div
    class="login-form-div"
    fxFlex="60"
    fxLayout="column"
  >
    <div
      class="heading ja-heading"
      fxLayoutAlign="center center"
    >
      {{translations[language]?.heading}}
    </div>
    <form
      [formGroup]="form"
      class="login-form"
      fxLayout="column"
      fxLayoutGap="1.2rem"
    >
      <app-text-input
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'email'"
        [error]="errorMessages['email']"
        [focus]="focus['email']"
        [label]="translations[language]?.emailLabel"
        [parentFormGroup]="form"
        [placeholder]="translations[language]?.emailPlaceholder"
        class="field-row username"
        id="email_address"
        maxLength=100
      >
      </app-text-input>
      <app-password-input
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'password'"
        [error]="errorMessages['password']"
        [focus]="focus['password']"
        [label]="translations[language]?.passwordLabel"
        [parentFormGroup]="form"
        [placeholder]="translations[language]?.passwordPlaceholder"
        class="field-row password"
        id="password"
        maxLength=20
      >
      </app-password-input>
      <app-primary-button
        (handleClick)="login()"
        [isDisabled]="false"
        id="LoginButton"
        text="{{translations[language]?.loginButton}}"
      ></app-primary-button>
    </form>
    <app-language-dropdown
      [languages]="languages"
      [selectedLanguage]="language">
    </app-language-dropdown>
  </div>
</div>
