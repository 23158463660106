import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-external-wrapper',
  templateUrl: './external-wrapper.component.html',
  styleUrls: ['./external-wrapper.component.css']
})
export class ExternalWrapperComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
