<div
  class="confirmation-dialog"
  fxLayout="column"
  fxLayoutGap="5rem"
>
  <div class="message">
    <span [innerHtml]="message"></span>
  </div>
  <div
    class="action-panel"
    fxLayout="column"
    fxLayoutGap="2rem"
  >
    <app-primary-button
      (handleClick)="handleSubmit()"
      *ngIf="showSubmit"
      [text]="submitText"
    ></app-primary-button>
    <app-secondary-button
      (handleClick)="handleCancel()"
      *ngIf="showCancel"
      [text]="cancelText"
    ></app-secondary-button>
  </div>
</div>
