<div
  class="wrapper"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="1.12rem"
>

  <app-icon
    class="profile-pic"
    icon="profile-avatar"
  ></app-icon>

  <div (menuClosed)="isClosed()"
       (menuOpened)="isOpened()"
       [matMenuTriggerFor]="menu"
       fxLayoutAlign="center center"
       fxLayoutGap="10px"
       id="profile_dropdown"
  >
    <div
      class="profile"
      fxLayout="column"
      fxLayoutAlign="start"
    >
      <div class="name">{{profileData.fullName}}</div>
      <div class="role ja-heading">{{profileData.roleName}}</div>
    </div>
    <app-icon
      [icon]="icon"
      class="icon"
    ></app-icon>
  </div>
</div>
<mat-menu #menu='matMenu'>
  <div
    (click)="eachOption.handleClick()"
    *ngFor="let eachOption of config"
    [id]="eachOption.id"
    class="dropdown"
    mat-menu-item
  >
    <span class="dropdown-item ja-heading">{{eachOption.name}}</span>
  </div>
</mat-menu>

