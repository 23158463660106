import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDatetimeModule} from '@mat-datetimepicker/moment';
import {MatDatetimepickerModule} from '@mat-datetimepicker/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';

// Modules
import {MaterialModule} from './material-module';

// Pipes
import {DateFormatPipe} from './pipes/date-format.pipe';
import {PhoneNumberFormatPipe} from './pipes/phone-number-format.pipe';

// Directives
import {DateDirective} from './directives/date.directive';
import {OnlyDigitsDirective} from './directives/only-digits.directive';
import {PhoneNumberDirective} from './directives/phone-number.directive';
import {PreventMultipleClickDirective} from './directives/prevent-multiple-click.directive';

// Components
import {DatePickerComponent} from './components/base/form-fields/date-picker/date-picker.component';
import {DateTimePickerComponent} from './components/base/form-fields/date-time-picker/date-time-picker.component';
import {IconComponent} from './components/base/icon/icon.component';
import {
  InternalPageNotFoundComponent
} from './components/base/internal-page-not-found/internal-page-not-found.component';
import {TextInputComponent} from './components/base/form-fields/input/text-input/text-input.component';
import {NumberInputComponent} from './components/base/form-fields/input/number-input/number-input.component';
import {PasswordInputComponent} from './components/base/form-fields/input/password-input/password-input.component';
import {
  PhoneNumberInputComponent
} from './components/base/form-fields/input/phone-number-input/phone-number-input.component';
import {PrimaryButtonComponent} from './components/base/buttons/primary-button/primary-button.component';
import {SecondaryButtonComponent} from './components/base/buttons/secondary-button/secondary-button.component';
import {SingleSelectComponent} from './components/base/form-fields/dropdown/single-select/single-select.component';
import {LoadingScreenComponent} from './components/base/loading-screen/loading-screen.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SharedEffects} from './core/shared.effects';
import {reducer} from './core/shared.reducer';
import {
  PrimaryClickableTextComponent
} from './components/base/buttons/primary-clickable-text/primary-clickable-text.component';
import {LabelTypeOneComponent} from './components/base/labels/label-type-one/label-type-one.component';
import {LabelTypeTwoComponent} from './components/base/labels/label-type-two/label-type-two.component';
import {LabelTypeThreeComponent} from './components/base/labels/label-type-three/label-type-three.component';
import {LabelTypeFourComponent} from './components/base/labels/label-type-four/label-type-four.component';
import {HorizontalDividerComponent} from './components/base/dividers/horizontal-divider/horizontal-divider.component';
import {TableComponent} from './components/base/table/table.component';
import {
  SideNavListComponent
} from './components/wrappers/components/side-nav-list/side-nav-list/side-nav-list.component';
import {TopNavBarComponent} from './components/wrappers/components/top-nav-bar/top-nav-bar/top-nav-bar.component';
import {
  ProfileMenuComponent
} from './components/wrappers/components/top-nav-bar/components/profile-menu/profile-menu.component';
import {
  LanguageDropdownComponent
} from './components/wrappers/components/top-nav-bar/components/language-dropdown/language-dropdown.component';
import {
  SideNavItemComponent
} from './components/wrappers/components/side-nav-list/components/side-nav-item/side-nav-item.component';
import {RouterModule} from '@angular/router';
import {BasicDialogComponent} from './components/base/dialogbox/basic-dialog/basic-dialog.component';
import {
  DatePickerTypeTwoComponent
} from './components/base/form-fields-type-two/date-picker-type-two/date-picker-type-two.component';
import {
  DateTimePickerTypeTwoComponent
} from './components/base/form-fields-type-two/date-time-picker-type-two/date-time-picker-type-two.component';
import {
  SingleSelectTypeTwoComponent
} from './components/base/form-fields-type-two/dropdown-type-two/single-select-type-two/single-select-type-two.component';
import {
  NumberInputTypeTwoComponent
} from './components/base/form-fields-type-two/input-type-two/number-input-type-two/number-input-type-two.component';
import {
  PasswordInputTypeTwoComponent
} from './components/base/form-fields-type-two/input-type-two/password-input-type-two/password-input-type-two.component';
import {
  PhoneNumberInputTypeTwoComponent
} from './components/base/form-fields-type-two/input-type-two/phone-number-input-type-two/phone-number-input-type-two.component';
import {
  TextInputTypeTwoComponent
} from './components/base/form-fields-type-two/input-type-two/text-input-type-two/text-input-type-two.component';
import {
  AuthenticatedWrapperComponent
} from './components/wrappers/authenticated-wrapper/authenticated-wrapper.component';
import {ExternalWrapperComponent} from './components/wrappers/external-wrapper/external-wrapper.component';
import {LoginFormComponent} from './pages/login-page/components/login-form/login-form.component';
import {LoginPageContainerComponent} from './pages/login-page/login-page-container/login-page-container.component';
import {LoginPageScreenComponent} from './pages/login-page/login-page-screen/login-page-screen.component';
import {SharedRoutingModule} from './shared-routing.module';
import {
  SendInvitationContainerComponent
} from './components/send-invitation/send-invitation-container/send-invitation-container.component';
import {
  SendInvitationScreenComponent
} from './components/send-invitation/send-invitation-screen/send-invitation-screen.component';
import {
  SendInvitationFormComponent
} from './components/send-invitation/components/send-invitation-form/send-invitation-form.component';
import {
  SignUpPageContainerComponent
} from './pages/sign-up-page/sign-up-page-container/sign-up-page-container.component';
import {SignUpPageScreenComponent} from './pages/sign-up-page/sign-up-page-screen/sign-up-page-screen.component';
import {SignUpFormComponent} from './pages/sign-up-page/components/sign-up-form/sign-up-form.component';
import {
  ExternalPageNotFoundComponent
} from './components/base/external-page-not-found/external-page-not-found.component';
import {
  ConfirmationDialogComponent
} from './components/base/dialogbox/confirmation-dialog/confirmation-dialog.component';
import {
  PageUnderMaintenanceComponent
} from './components/base/page-under-maintainance/page-under-maintenance.component';
import {
  TextAreaTypeTwoComponent
} from './components/base/form-fields-type-two/input-type-two/text-area-type-two/text-area-type-two.component';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {LabelTypeFiveComponent} from './components/base/labels/label-type-five/label-type-five.component';
import {
  ChipsTypeTwoComponent
} from './components/base/form-fields-type-two/input-type-two/chips-type-two/chips-type-two.component';
import {MatInputModule} from '@angular/material/input';
import {SafeHTMLPipe} from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    OnlyDigitsDirective,
    DatePickerComponent,
    DateTimePickerComponent,
    DateDirective,
    DateFormatPipe,
    PhoneNumberDirective,
    PreventMultipleClickDirective,
    IconComponent,
    InternalPageNotFoundComponent,
    TextInputComponent,
    NumberInputComponent,
    PasswordInputComponent,
    PhoneNumberInputComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    SingleSelectComponent,
    LoadingScreenComponent,
    PrimaryClickableTextComponent,
    LabelTypeOneComponent,
    LabelTypeTwoComponent,
    LabelTypeThreeComponent,
    LabelTypeFourComponent,
    HorizontalDividerComponent,
    TableComponent,
    SideNavListComponent,
    SideNavItemComponent,
    TopNavBarComponent,
    ProfileMenuComponent,
    LanguageDropdownComponent,
    BasicDialogComponent,
    DatePickerTypeTwoComponent,
    DateTimePickerTypeTwoComponent,
    SingleSelectTypeTwoComponent,
    NumberInputTypeTwoComponent,
    PasswordInputTypeTwoComponent,
    PhoneNumberInputTypeTwoComponent,
    TextInputTypeTwoComponent,
    AuthenticatedWrapperComponent,
    ExternalWrapperComponent,
    LoginFormComponent,
    LoginPageContainerComponent,
    LoginPageScreenComponent,
    SendInvitationContainerComponent,
    SendInvitationScreenComponent,
    SendInvitationFormComponent,
    SignUpPageContainerComponent,
    SignUpPageScreenComponent,
    SignUpFormComponent,
    ExternalPageNotFoundComponent,
    ConfirmationDialogComponent,
    PageUnderMaintenanceComponent,
    TextAreaTypeTwoComponent,
    SafeUrlPipe,
    LabelTypeFiveComponent,
    ChipsTypeTwoComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    FlexModule,
    MatInputModule,
    StoreModule.forFeature('shared', reducer),
    EffectsModule.forFeature(
      [SharedEffects]
    ),
    RouterModule,
    ExtendedModule,
    SharedRoutingModule,
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DatePickerComponent,
    DateTimePickerComponent,
    TextInputComponent,
    NumberInputComponent,
    PasswordInputComponent,
    PhoneNumberInputComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    SingleSelectComponent,
    PrimaryClickableTextComponent,
    LabelTypeTwoComponent,
    HorizontalDividerComponent,
    LabelTypeThreeComponent,
    TableComponent,
    IconComponent,
    SideNavListComponent,
    SideNavItemComponent,
    TopNavBarComponent,
    ProfileMenuComponent,
    LanguageDropdownComponent,
    BasicDialogComponent,
    LabelTypeFourComponent,
    DatePickerTypeTwoComponent,
    DateTimePickerTypeTwoComponent,
    SingleSelectTypeTwoComponent,
    NumberInputTypeTwoComponent,
    PasswordInputTypeTwoComponent,
    PhoneNumberInputTypeTwoComponent,
    TextInputTypeTwoComponent,
    SharedRoutingModule,
    TextAreaTypeTwoComponent,
    SafeUrlPipe,
    LabelTypeFiveComponent,
    ChipsTypeTwoComponent
  ],
  providers: [
    DatePipe,
    DateFormatPipe,
    PhoneNumberFormatPipe,
    SafeHTMLPipe
  ]
})
export class SharedModule {
}
