import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {CommonService} from '../common/common.service';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

export let InterceptorSkipHeader: string;
InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {
  messageQueue = [];
  processingMessage = false;

  excludedUrlsRegex: RegExp[];
  excludedUrls = ['.svg'];

  constructor(
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.excludedUrlsRegex =
      this.excludedUrls.map(urlPattern => new RegExp(urlPattern, 'i')) || [];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const passThrough: boolean =
      !!this.excludedUrlsRegex.find(regex => regex.test(req.url));

    if (passThrough) {
      return next.handle(req);
    }

    let headers = req.headers;
    if (!headers.get('file-upload')) {
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('language', this.commonService.getLanguage().toString());
      headers = headers.append('country-code', this.commonService.getCountryCode());
      headers = headers.append('app-version', '1.0.0');
      headers = headers.append('platform', 'admin-dashboard');
    }

    const body = req.body;
    let requestToSend;

    if (!headers.get('file-upload') && this.commonService.isAuthenticated()) {
      const token = this.commonService.getAuthenticationToken();
      headers = headers.append('authorization', `Bearer ${token}`);
    }

    requestToSend = req.clone({headers, body});
    return next.handle(requestToSend).pipe(
      map((response) => {
        if (
          (response['body'] && !response['body'].success) ||
          ('success' in response && response['success'] === false)
        ) {
          throw response;
        }

        return response;
      }),
      catchError((errorResponse) => this.handleError(errorResponse))
    );
  }

  handleError(response) {
    if (response.status === 503) {
      let currentUrl = this.router.url;
      currentUrl = currentUrl.split('/').pop();
      if (currentUrl !== 'maintenance') {
        this.router.navigate(['/maintenance']);
      }
      return;
    }

    if (response.status === 401 || response.status === 0) {
      if (response.url.indexOf('/count') >= 0) {
        return;
      }

      if (this.commonService.isAuthenticated()) {
        if (response.url.indexOf('/login') >= 0 || response.url.indexOf('/common/invite/accept') >= 0) {
          this.commonService.clearAppState();
          this.commonService.clearStorage();
        } else {
          this.router.navigate(['/login']).then(() => {
            this.commonService.clearAppState();
            this.commonService.clearStorage();
          });
        }
      } else {
        if (response.url.indexOf('/login') < 0) {
          this.router.navigate(['/login']).then(() => {
            this.commonService.clearAppState();
            this.commonService.clearStorage();
          });
        }
      }
    }

    const errorObject = (response && response.error) || (response && response.body) || {};

    if (!errorObject.message) {
      errorObject.message = response.message || 'Oops! Something went wrong.';
    }

    if (errorObject.validationErrorStack) {
      errorObject.validationErrorStack.forEach(eachValidationError => {
        this.addMessageToQueue(eachValidationError.message);
      })
    } else {
      this.addMessageToQueue(errorObject.message);
    }

    return throwError(errorObject);
  }

  addMessageToQueue(message) {
    this.messageQueue.push(message);

    if (!this.processingMessage) {
      this.displaySnackbar();
    }
  }

  displaySnackbar() {
    const nextMessage = this.getNextMessage();

    if (!nextMessage) {
      this.processingMessage = false; // No message in the queue, set flag false
      return;
    }

    this.processingMessage = true; // A message was dequeue and is being processed

    this.snackBar
      .open(nextMessage, undefined, {
        duration: 1500,
        verticalPosition: 'bottom', // 'top' | 'bottom'
        horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
        panelClass: 'danger',
      })
      .afterDismissed()
      .subscribe(() => {
        this.displaySnackbar();
      });
  }

  getNextMessage(): string | undefined {
    return this.messageQueue.length ? this.messageQueue.shift() : null;
  }
}


