<div
  [ngClass.gt-lg]="'xl'"
  [ngClass.lg]="'lg'"
  [ngClass.md]="'md'"
  [ngClass.sm]="'sm'"
  [ngClass.xs]="'xs'"
  class="container"
  fxLayoutAlign="center center"
>
  <div class="diffuser-panel">
    <img
      alt="black-diffuser-top"
      class="diffuser-top"
      src="../../../../../../assets/images/png/diffuser_top.png"
    >
    <img
      alt="black-diffuser-bottom"
      class="diffuser-bottom"
      src="../../../../../../assets/images/png/diffuser_bottom.png"
    >
  </div>
  <div
    class="page-not-found-box"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="center center"
    fxLayoutGap="175"
    fxLayoutGap.md="100"
    fxLayoutGap.sm="0"
    fxLayoutGap.xs="5"
  >
    <div
      class="teplo-container"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutAlign.md="center center"
    >
      <img
        alt="teplo-logo"
        class="teplo-logo"
        src="../../../../../../assets/images/png/teploLogo.png"
      >
      <img
        alt="teplo-logo"
        class="teplo-tea-pot"
        fxShow.sm="false"
        fxShow.xs="false"
        src="../../../../../../assets/images/png/teploTeaPot.png"
      >
    </div>
    <div
      class="error "
      fxLayout="column"
      fxLayoutAlign="center"
    >
      <div
        class="error-code "
        fxLayoutAlign="center"
      >
        404
      </div>
      <div
        class="error-message "
        fxLayoutAlign="center"
      >
        {{translations[language].errorMessage}}
      </div>
      <div
        class="message "
      >
        {{translations[language].message}}
      </div>
      <app-primary-button
        (handleClick)="redirectToLogin()"
        [text]="translations[language]?.loginButton"
        class="login-button"
        id="login_button"
      ></app-primary-button>
    </div>

  </div>

</div>
