interface SendInvitationFormTranslation {
  heading: string,
  companyFilterListLabel: string,
  companyFilterListPlaceholder: string,
  emailLabel: string,
  emailPlaceholder: string,
  roleFilterListPlaceholder: string,
  roleFilterListLabel: string,
  sendInvitationButton: string,
  errorMessageMap: object
}

const en: SendInvitationFormTranslation = {
  heading: 'Send Invitation',
  companyFilterListLabel: 'Company Name',
  companyFilterListPlaceholder: 'Company Name',
  emailLabel: 'Email Address',
  emailPlaceholder: 'Email Address',
  roleFilterListLabel: 'Role',
  roleFilterListPlaceholder: 'Role',
  sendInvitationButton: 'Send Invitation',
  errorMessageMap: {
    companyId: {
      required: 'This field is required.'
    },
    roleId: {
      required: 'This field is required.'
    },
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email.'
    },
  },
}

const ja: SendInvitationFormTranslation = {
  heading: '招待を送信',
  companyFilterListLabel: '会社名',
  companyFilterListPlaceholder: '会社名',
  emailLabel: 'メールアドレス',
  emailPlaceholder: 'メールアドレス',
  roleFilterListLabel: '役割',
  roleFilterListPlaceholder: '役割',
  sendInvitationButton: '招待を送信',
  errorMessageMap: {
    companyId: {
      required: '入力が必須な項目です'
    },
    roleId: {
      required: '入力が必須な項目です'
    },
    email: {
      required: '入力が必須な項目です',
      pattern: '無効なメールアドレス'
    },
  },
}

export const Translations = {
  1: en,
  2: ja
}
