<div
  class="send-invitation-form-component"
  fxLayoutAlign="center center"
>
  <div
    class="send-invitation-form-div"
    fxLayout="column"
  >
    <div
      class="heading ja-heading"
      fxLayoutAlign="center none"
    >
      {{translations[language]?.heading}}
    </div>
    <form
      [formGroup]="form"
      class="send-invitation-form"
      fxLayout="column"
    >
      <app-single-select
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'roleId'"
        [error]="errorMessages['roleId']"
        [label]="translations[language]?.roleFilterListLabel"
        [options]="singleSelectOptions"
        [parentFormGroup]="form"
        class="input-field"
        id="role_id"
        placeholder="{{translations[language].roleFilterListPlaceholder}}"
      >
      </app-single-select>
      <app-single-select
        (checkForErrors)="checkForErrors($event)"
        *ngIf="form.controls.roleId.value === roles.inventoryPartner"
        [controlName]="'companyId'"
        [error]="errorMessages['companyId']"
        [label]="translations[language]?.companyFilterListLabel"
        [options]="singleSelectNameOptions"
        [parentFormGroup]="form"
        class="input-field"
        id="company_id"
        placeholder="{{translations[language].companyFilterListPlaceholder}}"
      >
      </app-single-select>
      <app-text-input
        (checkForErrors)="checkForErrors($event)"
        [controlName]="'email'"
        [error]="errorMessages['email']"
        [label]="translations[language].emailLabel"
        [parentFormGroup]="form"
        [placeholder]="translations[language].emailPlaceholder"
        class="input-field"
        id="email"
        maxLength=100
      >
      </app-text-input>
      <app-primary-button
        (handleClick)="sendInvite()"
        [isDisabled]="false"
        id="send_invitation_button"
        text="{{translations[language].sendInvitationButton}}"
      ></app-primary-button>
    </form>
  </div>
</div>
